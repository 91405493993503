import { 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText, 
  DialogActions } from '@mui/material'

// styles
import './WarningDialog.css'

const WarningDialog = ({ open, title, message, primaryButton, secondaryButton, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby='dialog-title' aria-describedby='dialog-description'>
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='dialog-message'>{message}</DialogContentText>
      </DialogContent>
      <DialogActions className='warning-dialog-buttons'>
        <Button variant='contained' color='secondary' onClick={primaryButton.onClick}>
          {primaryButton.label}
        </Button>
        <Button variant='outlined' color='secondary' onClick={secondaryButton.onClick}>
          {secondaryButton.label}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WarningDialog
