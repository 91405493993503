import { useState, useEffect, useRef } from 'react'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useHistory } from 'react-router-dom';
import { 
  TextField, 
  Button, 
  CircularProgress,
  InputAdornment,
} from '@mui/material';
//firebase
import firebase from 'firebase/app';
import { db } from '../../firebase/config';
//form validation
import badWords from "bad-words";
import { useFormik } from "formik";
import * as Yup from "yup";
//styles
import "./CreateCardForm.css";



//***************************** IMPORTANT *******************************//
    
    //comment out the following line to use the production database
    //firebase.functions().useEmulator("localhost", 5001);

//**********************************************************************//
export default function CreateCardForm({ packId }) {

  const { user } = useAuthContext();
  const userId = user.uid;

  const history = useHistory();

  const [formState, setFormState] = useState({
    front: '',
    back: '',
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const frontInputRef = useRef(null);
  const backInputRef = useRef(null);
  const formRef = useRef(null);

  const [isTranslating, setIsTranslating] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [hasTranslated, setHasTranslated] = useState(false);
  const [hasRecordedAudio, setHasRecordedAudio] = useState(false);
  const [cardIsComplete, setCardIsComplete] = useState(false);

  const [phonetics, setPhonetics] = useState("");
  const [audioFileName, setAudioFileName] = useState("");
  //limit to 120 characters
  const [inputValue, setInputValue] = useState("");

  const maxChars = 50
  
  // const maxLength = 20;
  // const remaining = maxLength - inputValue.length;
  // const isOverLimit = remaining <= 0;
  
  const initialValues = {
    front: "" || formState.front,
    back: "" || formState.back,
  };

  const validationSchema = Yup.object({
    front: Yup.string().required(""),
    back: Yup.string().required(""),
  });

  function handleFrontBlur (event){
    const filter = new badWords();
    const frontTxt = event.target.value;
    const filtered = filter.clean(frontTxt);
    formik.setFieldValue("front", filtered);
  }

  function handleMakeAudioClip(){
    setIsRecording(true);
    const makeAudioClip = firebase.functions().httpsCallable('makeAudioClip');
    const text = values.back.toLowerCase();
    const languageCode = localStorage.getItem("targetLanguage");
    const name = localStorage.getItem("targetVoice");
    const rate = localStorage.getItem("speechRate");


    makeAudioClip({ text, languageCode, name, rate, userId})
    .then((result) => {
        console.log(result);
        setIsRecording(false);
        setIsTranslating(false);
        setIsRecording(false);
        setHasTranslated(false);
        setHasRecordedAudio(false);
        setCardIsComplete(true);
        
        const audioFileName = result.data.audioFileName;
        setAudioFileName(audioFileName);

        if(backInputRef.current){
          backInputRef.current.blur();
          frontInputRef.current.focus()
        }
    
        // Update Firestore document
        const cardData = {
          audioFile: `${audioFileName}`,
          front: values.front,
          back: values.back,
          phonetics: phonetics ? phonetics : "",
          collection: packId,
          createdAt: firebase.firestore.FieldValue.serverTimestamp(),
          isActive: true,
          cardType: "textCard",
          speakingRate: rate
        };
      
        db.collection(`users/${userId}/packs/${packId}/cards/`)
          .doc(values.front)
          .set(cardData)
          .then(() => {
            console.log("New card saved to Firestore");
            formik.setFieldValue("front", "");
            formik.setFieldValue("back", "");
            setFormSubmitted(true);
          })
          .catch((error) => {
            console.error("Error saving to Firestore: ", error);
          });
      })
  }

  const handleTranslation = () => {
    setIsTranslating(true);
  
    const translate = firebase.functions().httpsCallable('translateFront');
    const targetVoice = localStorage.getItem("targetVoice");
    const target = targetVoice.substring(0, 2) === 'cm' ? 'zh' : targetVoice.substring(0, 2);
    const txt = values.front; //front of card

    translate({txt, target}).then((result) => {
      console.log(result);
      formik.setFieldValue("back", result.data.back.toString());
      setPhonetics(result.data.phonetics);
      setIsTranslating(false);
      setHasTranslated(true);
    })
  }

  useEffect(() => {
    if (formSubmitted) {
        setFormSubmitted(false);
        setIsTranslating(false);
        setHasTranslated(false);
        setIsRecording(false);
        setHasRecordedAudio(false);
        setCardIsComplete(true);
        if (frontInputRef.current) {
            frontInputRef.current.focus();
        } 
    }
}, []);

function formatString(str) {
  const sanitized = str.replace(/[^\p{L}\d]+/gu, ' ').normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  const cleaned = sanitized.toLowerCase().replace(/\s+/g, '_').replace(/^_+|_+$/g, '');
  const polished = cleaned.replace(/[äöüß]/g, function(match) {
    switch (match) {
      case "ä":
        return "ae";
      case "ö":
        return "oe";
      case "ü":
        return "ue";
      case "ß":
        return "ss";
      default:
        return "";
    }
  });
  return polished;
}

  const onSubmit = (values, { setFieldValue }) => {
    // handle form submission here
    console.log("submitting form");
    console.log(values);
    setIsTranslating(false);
    setIsRecording(false);
    setHasRecordedAudio(false);
    setHasTranslated(false);
    setCardIsComplete(true);
    //call makeAudioClip function
    handleMakeAudioClip();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const {
    values, 
    touched, 
    errors, 
    handleChange, 
    handleBlur, 
    handleSubmit,
    setFieldValue
  } = formik;

  const handleFrontKeyDown = (event) => {
    setInputValue(event.target.value);//count characters
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      handleTranslation();
      if(frontInputRef.current){
        frontInputRef.current.blur();
        backInputRef.current.focus()
      } 
    }
  };

  const handleBackKeyDown = (event) => {

    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission
      if(!hasTranslated || !hasRecordedAudio){
        let audioFileName = handleMakeAudioClip();
      } else if(hasTranslated && hasRecordedAudio){
      
        if(backInputRef.current){
          //backInputRef.current.blur();
          frontInputRef.current.focus()
        }
        handleSubmit();
      }
    
    }
  };

  const characterCount = () => {
    if(values.front.length > 0){
      return <span className="characterCount">{values.front.length}</span>
    }
  }

  return (
    <div className="createCardContainer">
      <div className="studio-form-header">

          <div className="studio-form-header-left">
            {!isTranslating && !isRecording ? <span className="studio-form-header-title">Create a Card</span> : <></>}
            {isTranslating && !isRecording ? <span className="studio-form-header-title">Getting Translation</span> : <></>}
          </div>
      
          {!isTranslating && isRecording ? <span className="studio-form-header-title">Recording Audio</span> : <></>}

          {/* {cardIsComplete ? <span className="studio-form-header-title"
          style={{color: 'var(--clr-danger1'}}>Save Your Card</span> : <></>} */}

      </div>

      <form onSubmit={handleSubmit} className="createCardForm" ref={formRef}>
        <div className="studio-input-container">
          <span className="studio-form-label">Front*</span>
          <TextField
              inputRef={frontInputRef}
              required
              disabled={isTranslating || isRecording}
              placeholder="Enter text to translate"
              name="front"
              variant="outlined"
              margin="normal"
              onChange={handleChange}
              onKeyDown={handleFrontKeyDown}
              onBlur={values.front && handleFrontBlur}
              error={touched.front && Boolean(errors.front)}
              value={values.front}
              helperText={touched.front && errors.front}
              InputProps={{
                style: { height: 40, lineHeight: 2.5},
                endAdornment: isTranslating && (
                  <InputAdornment position="end">
                    <CircularProgress color="inherit" size={20} /> 
                  </InputAdornment>
                  ),
              }}
              fullWidth
              className="frontTextField"
              maxLength={80}
            />

            <div className="help-text-container">

              {!hasTranslated && <span className="studio-help-text">press RETURN for translation</span>}
          
              {!hasTranslated && values.front.length < 70 && <span className="studio-help-text-counter">{values.front.length} of 80</span>}
              {!hasTranslated && values.front.length >= 70 && <span className="studio-help-text-counter" style={{color: 'red'}}>{values.front.length} of 80</span>}
        
              {hasTranslated && <span className="studio-help-text-green">translation complete</span>}

            </div>
        </div>

        <div className="studio-input-container" style={{marginTop: '-2px', border: '0px solid red'}}>
          <span className="studio-form-label">back*</span>
          <TextField
            inputRef={backInputRef}
            required
            disabled={isRecording}
            fullWidth
            placeholder="...translation will appear here"
            name="back"
            variant="outlined"
            multiline={true}
            margin="normal"
            onChange={handleChange}
            onKeyDown={handleBackKeyDown}
            value={values.back}
            helperText={touched.back && errors.back}
            // helperText={`${characterCount}/${props.maxLength}`}
            className="backTextField"
            InputProps={{
              style: { height: 40},
              endAdornment: isRecording && (
              <InputAdornment position="end">
                <CircularProgress color="inherit" size={20} /> 
              </InputAdornment>
              )}}
          />
          <div className="help-text-container">
          {!isRecording && !hasRecordedAudio &&
          <div className="studio-help-text">
            press RETURN for Audio Clip
          </div>}
        
          {hasRecordedAudio &&  
          <span className="studio-help-text-green">
            audio clip complete
          </span>}
        </div>
        </div>

        <div className="submitBtnContainer">
         {!isTranslating && !hasTranslated && <Button 
            variant="contained" 
            color="primary" 
            onClick={handleTranslation}
            disabled={isTranslating || values.front.length <= 3}
            type="text"
            className="submitBtn"
            style={{
              background: cardIsComplete 
              ? 'var(--clr-danger1)' 
              : 'var(--clr-tertiary1))', 
              color: 'var(--clr-white)', 
              fontWeight: 'bold',
              opacity: isTranslating || values.front.length <= 3 ? '0.5' : '1',
            }}
            > Translate Text
          </Button>}
          {hasTranslated && <Button 
            variant="contained" 
            color="primary" 
            type="submit"
            disabled = {isRecording || hasRecordedAudio || !hasTranslated}
            className="submitBtn"
            style={{
              background: cardIsComplete 
              ? 'var(--clr-danger1)' 
              : 'var(--clr-tertiary1))', 
              color: 'var(--clr-white)', 
              fontWeight: 'bold',
              opacity: isRecording || hasRecordedAudio ? '0.5' : '1',
            }}
            > Make Audio & Save
          </Button>}
        
        </div>
      
      </form>
  
    </div>
    
  
  
  )
}