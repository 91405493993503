import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/firebase-storage'
import 'firebase/firebase-functions'

const firebaseConfig = {
  apiKey: "AIzaSyDtVbeTCL6ANIwxq7x0zypdP_PqGMkKNUI",
  authDomain: "lwr-dev.firebaseapp.com",
  projectId: "lwr-dev",
  storageBucket: "lwr-dev.appspot.com",
  messagingSenderId: "772321751813",
  appId: "1:772321751813:web:d1836c008e686dc6bc7d6c",
  serviceAccountKeyPath: "./lwr-dev-firebase-adminsdk-45baz-c373255d39.json"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const functions = firebase.functions()

export { db, auth, storage, functions }