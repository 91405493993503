import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Radio,
    RadioGroup,
    FormGroup,
    FormControl,
    FormControlLabel,
} from '@mui/material'

//styles
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    label: {
        fontSize: '1rem',
        fontWeight: 'normal',
        color: 'var(--clr-txt-dark)',
    },
    radio: {
        '&$checked': {
          color: 'var(--clr-secondary1)',
        }
      },
        checked: {},
})



export const SelectLang = ({open, setOpen, packType, selectedPack}) => {
  const target = localStorage.getItem('targetLang')
  const [targetLang, setTargetLang] = useState(target)
  const history = useHistory()

  const handleSubmit = () => {
    console.log("selectedPack: ", selectedPack)
    localStorage.setItem('targetLang', targetLang);
    setOpen(false);
    history.push(`${packType}/${selectedPack}`);
  }

  const classes = useStyles()

  return (
    <>
        <Dialog 
          open={open}
          onClose={()=> setOpen(false)}
          aria-labelledby='dialog-title' 
          aria-describedby='dialog-description'
          >
            <DialogTitle id='dialog-title' variant="h5">
                Select Target Language
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='dialog-description' 
              style={{fontSize: '1.25rem'}}
              gutterBottom
              >
                Which language would you like to learn?
              </DialogContentText>

            <FormGroup onSubmit = {handleSubmit} style={{marginTop: '20px'}}>
              <FormControl>
              <RadioGroup value={targetLang} onChange={(e) => setTargetLang(e.target.value)}>
                <FormControlLabel 
                    value="nl" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Dutch" />
                <FormControlLabel 
                    value="fr" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="French" />
                <FormControlLabel 
                    value="de" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="German" />
                <FormControlLabel 
                    value="el" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Greek" />
                <FormControlLabel 
                    value="it" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Italian" />
                <FormControlLabel 
                    color="primary"
                    value="pl" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />}
                    label="Polish" />
                <FormControlLabel 
                    value="pt" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Portuguese" />
                <FormControlLabel 
                    value="es" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Spanish" />
                {/* <FormControlLabel 
                    value="hi" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Hindi" />
                <FormControlLabel 
                    value="uk" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Ukrainian" />
                <FormControlLabel 
                    value="ru" 
                    control = {<Radio classes={{root: classes.radio, checked: classes.checked}} />} 
                    label="Russian" /> */}
             </RadioGroup>
             </FormControl>
             </FormGroup>

            </DialogContent>
            <DialogActions style={{
              margin: '0 10px 10px 10px',
            }}>
              <Button 
              variant='outlined'
              color='secondary'
              onClick={() => setOpen(false)}
              >Cancel</Button>

              <Button autoFocus 
              variant='contained'
              color='primary'
              type="submit"
              onClick={() => handleSubmit()}
              >OK</Button>
            </DialogActions>
        </Dialog>
    </>
  )
}

export default SelectLang
