import React, { useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogContentText,
  DialogActions, 
  Button } from '@mui/material';
import passedQuiz from '../../assets/snd/passedQuiz.wav';
import ConfettiExplosion from 'react-confetti-explosion';

//styles
import { makeStyles } from '@mui/styles';

export default function PresentScoreDialog({ 
    open, 
    score, 
    onClose,
    audioOn,
    isExploding
 }) {
  const { user } = useAuthContext()
  const useStyles = makeStyles((theme) => ({
    dialog: {
      minWidth: 400,
      minHeight: 200,
      borderRadius: '5%',
      marginTop: '-20px'
    },
    paper: {
        borderRadius: 20,
        backgroundImage: 'linear-gradient(45deg, #121212 30%, var(--clr-primary1) 90%)',
    },
  }));

  const classes = useStyles();
  const title = score >= 70 ? `Well done ${user.displayName}!` : 'Awe - better luck next time';
  const message = 'you scored'

  function handleScore(score){
      
      if (score >= 70) {
          playCelebrationSnds();
      } 
  }
 
  function playCelebrationSnds(){
    const audio = new Audio(passedQuiz);
        if(audioOn){
          audio.play();
        }
  }
  
  useEffect(() => {
      handleScore(score);
  }, [score, audioOn]);


  return (
    <>
  
    <Dialog 
    open={open} 
    onClose={onClose} 
    classes={{ paper: classes.dialog }} 
    PaperProps={{
      classes: { root: classes.paper },
      elevation: 10, // <-- remove box shadow
       // <-- add border radius
    }}
    BackdropProps={{
      invisible: true, // <-- set backdrop to invisible
      classes: { root: classes.backdrop }, // <-- add backdrop class
    }}
    >
      <img src="../../assets/intro-o.svg" alt="Owlivia"
      style={{
        width: '30%', 
        margin: '10px auto',
        paddingTop: '10px',
        height: 'auto'
        }}/>
      <DialogTitle 
      style={{
        fontWeight: '500',
        textAlign: 'center',
        fontSize: '1.5rem',
        color: 'white',
        lineHeight: '1rem'
      }}
      className="presentScoreDialog"
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText 
        id='dialog-message'
        style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          fontWeight: '500',
          color: 'var(--clr-text-light)'
       }}
        >
          {`${message}  ${score}%`}
          </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
        onClick={onClose}
        variant='contained'
        color='primary'
        style={{
          margin: '20px',
          width: '100%',
        }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
    {isExploding && <ConfettiExplosion 
      count={12000}
      force={0.8}
      duration={3000}
      opacity={1}
      style={{
        position: 'absolute', 
        top: 0, left: '50%',
        zIndex: 12000
      }}
    />}
    </>
  );
}
