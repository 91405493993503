import { useState } from 'react'
import {ReactComponent as ThumbsdownIcon} from "../../assets/icons/thumbs-down-solid.svg";
import {ReactComponent as ThumbsupIcon} from "../../assets/icons/thumbs-up-solid.svg";

//styles & audio
import './TextCard.css'

export default function TextCard({ 
  id, 
  doc, 
  front, 
  back,
  phonetics,
  onHandleAnswer,
  onLingoAudio,
}) {
    const [isFlipped, setIsFlipped] = useState(false)
  
    const handleClick = ()=> {
        setIsFlipped(!isFlipped)
        if(!isFlipped){
          onLingoAudio(doc.audioFile);
        }
    }

  return (
    <div className="card-container" onClick={handleClick}>
        <div className={`card ${isFlipped ? "flipped" : ""}`}>
          <div className="front">
            <span className="textCard-frontText">{doc[front]}</span>
          </div>
          <div className="back">
          {doc.phonetics && <p className="phonetics">{doc.phonetics}</p>}
            <span className="textCard-backText">{doc[back]}</span>

            <div className="buttons" style={{
                position: 'absolute',
                bottom: '90px',
                width: '100%',
              }}>

              <ThumbsdownIcon
                onClick={() => onHandleAnswer(false)}
                style={{
                  fill: 'var(--clr-secondary1)',
                  width: '3rem',
                  marginLeft: '20px',
                  marginTop: '10px',
                }} />

              <ThumbsupIcon
                onClick={() => onHandleAnswer(true)}
                style={{
                  fill: 'var(--clr-success)',
                  width: '3rem',
                  marginRight: '20px',
                }} />
            </div>
          </div>
        </div>
      </div>
  )
}
