import * as React from 'react'
import { useState, useEffect } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';
import SelectLang from '../../components/dialogs/SelectLang'
//styles
import "./Gallery.css";

export default function SnackPacksList() {
  const [open, setOpen] = useState(false)
  const [packType, setPackType] = useState("snack-packs")
  const [selectedPack, setSelectedPack] = useState("")

  const [snackPacks, setSnackPacks] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory()

  const hasSearchValue = Boolean(searchValue);

  useEffect(() => {
    const storedPacks = localStorage.getItem('snackpacks')
    setSnackPacks(JSON.parse(storedPacks) || []) // Set to empty array if no data in localStorage

  }, [])

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const handleClick = (pack) => {
    if(pack.isLangPack){
      setSelectedPack(pack.id);
      setOpen(true);
    } else if(!pack.isLangPack){
      setSelectedPack(pack.id);
      history.push(`${packType}/${selectedPack}`)
    }
  }

  return (
    <>
    <div className="listNav">
      <p className="listNavTitle">Snack Packs</p>
      <TextField
      value={searchValue}
      onChange={handleSearchChange}
      placeholder="Search"
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        endAdornment: hasSearchValue ? (
          <ClearIcon sx={{ ml: 1, cursor: 'pointer' }} onClick={handleClearSearch} />
        ) : (
          <SearchIcon sx={{ ml: 1, mr: -1 }} />
        ),
        sx: {
          position: 'absolute',
          marginTop: '10px',
          right: '40px',
          width: '50%',
          borderRadius: '4px',
          backgroundColor: 'white',
          boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.3)',
          '& .MuiInputBase-input': {
            py: '10px',
            px: '10px',
            transition: 'none',
          },
          '&:hover': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          },
          '&.Mui-focused': {
            boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
          },
        },
      }}
    />
   </div>
  

      <List className="packs-list">
        {snackPacks.filter(pack => pack.title.toLowerCase().includes(searchValue.toLowerCase())).sort((a, b) => a.LWid - b.LWid).map((pack) => {
          if(pack.isActive){
          return (
            <React.Fragment key={pack.id}>
            
              <ListItem 
              className="pack"
              onClick={()=>handleClick(pack)}
              >
                <ListItemAvatar>
                  <img
                    src={pack.coverImage}
                    alt=" "
                    className="snack-thumb"
                  />
                </ListItemAvatar>  

                <ListItemText 
                  primary={pack.title} 
                  secondary={`Total cards:  ${pack.numbCards}`}
                  style={{color: 'var(--clr-text-dark)'}}
                />
              
              </ListItem>
        
              <Divider variant="inset" component="li" />
            </React.Fragment>
          )
          } else {
            return null
          }
        })}
      </List>
    
      <SelectLang packType={packType} selectedPack={selectedPack} open={open} setOpen={setOpen} />
    
    </>
  )
}
