import React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import VolumeUpSharpIcon from '@mui/icons-material/VolumeUpSharp';
import VolumeOffSharpIcon from '@mui/icons-material/VolumeOffSharp';
import VolumeDownSharpIcon from '@mui/icons-material/VolumeDownSharp';

function AudioPopover(props) {
  const { audioOn, soundEffectsOn, handleAudioToggle, handleSoundEffectsToggle } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{display: 'flex', marginLeft: 'auto'}}>
      <IconButton 
      onClick={handleClick} 
      style={{
        marginTop: '-4px',
        marginRight: '10px',
        width:'5vw', 
        height: '5vh',
        minWidth: '80px',
        minHeight: '60px',
        border: '0px dotted green',
      }}>
        {audioOn && soundEffectsOn &&  <VolumeUpSharpIcon 
          style={{
            width: '100%',
            height: '100%',
            color: 'white',
            fontSize: '2rem',
            marginRight: '10px',
        }}/>}
        {!audioOn && soundEffectsOn && <VolumeDownSharpIcon 
          style={{
            width: '100%',
            height: '100%',
            color: 'white',
            fontSize: '2rem',
            marginRight: '10px',
        }}/>}  
          {audioOn && !soundEffectsOn && <VolumeDownSharpIcon 
          style={{
            width: '100%',
            height: '100%',
            color: 'white',
            fontSize: '2rem',
            marginRight: '10px',
        }}/>}   
        {!audioOn && !soundEffectsOn && <VolumeOffSharpIcon 
          style={{
            width: '100%',
            height: '100%',
            color: 'white',
            fontSize: '2rem',
            marginRight: '10px',
        }}/>}
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 2 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch checked={audioOn} onChange={handleAudioToggle} />
            <div style={{ marginLeft: '8px' }}>Audio</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Switch checked={soundEffectsOn} onChange={handleSoundEffectsToggle} />
            <div style={{ marginLeft: '8px' }}>Sound Effects</div>
          </div>
        </Box>
      </Popover>
    </div>
  );
}

export default AudioPopover;
