import { useState, useEffect } from 'react'
import {ReactComponent as ThumbsdownIcon} from "../../assets/icons/thumbs-down-solid.svg";
import {ReactComponent as ThumbsupIcon} from "../../assets/icons/thumbs-up-solid.svg";
//styles & audio
import './BannerCard.css'


export default function BannerCard({
  id, 
  doc, 
  onHandleAnswer,
  onLingoAudio,
}) {
    
    const [bgColor, setBgColor] = useState('');

    //change random banner color each time document changes
    useEffect(() => {
      function getRandomColor() {
        const hexChars = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
          color += hexChars[Math.floor(Math.random() * 16)];
        }
        return color;
      }
  
      setBgColor(getRandomColor());
    }, [doc]);
  
  const [isFlipped, setIsFlipped] = useState(false);
  const handleClick = async () => {
    setIsFlipped(!isFlipped);
    if(!isFlipped){
      onLingoAudio(doc.audioFile);
    }
  }

  return (
    <div className="card-container" onClick={handleClick}>
    <div className={`card ${isFlipped ? "flipped" : ""}`}>
      <div className="front">
        <div className="colorBanner-front"   
        style={{ 
          backgroundColor: bgColor,
        }}
        >
          <span className="bannerCard-frontText">{doc.front}</span>
        </div>
      </div>
      <div className="back">
      <div className="colorBanner-back"
      style={{backgroundColor: bgColor }}
      >
          <span className="bannerReverseText">{doc.front}</span>
        </div>
        <span className="bannerCard-backText">{doc.back}</span>

          <div className="buttons" style={{
            position: 'absolute',
            bottom: '90px',
            width: '100%',
          }}>

          <ThumbsdownIcon
            onClick={() => onHandleAnswer(false)}
            style={{
              fill: 'var(--clr-secondary1)',
              width: '3rem',
              marginLeft: '20px',
              marginTop: '10px',
            }} />

          <ThumbsupIcon
            onClick={() => onHandleAnswer(true)}
            style={{
              fill: 'var(--clr-success',
              width: '3rem',
              marginRight: '20px',
            }} />
        </div>

      </div>
    </div>
  </div>
  )
}
