import {useState} from 'react'
import { Swiper, SwiperSlide }  from 'swiper/react'
import VideoModal from '../../components/dialogs/VideoModal'
// styles
import './Gallery.css'
import 'swiper/css'

export default function TutorialsCarousel({ tutorials }) {
   const [isOpen, setIsOpen] = useState(false);
   const [video, setVideo] = useState(null);
   const [title, setTitle] = useState('');
   const [runtime, setRuntime] = useState('');
   const [description, setDescription] = useState('');
   const [src, setSrc] = useState('');

   const handleItemClick = (videoData) => {
    setVideo(videoData.video);
    setTitle(videoData.title);
    setRuntime(videoData.runtime);
    setDescription(videoData.description);
    setSrc(videoData.src);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };
  
  return (
    <>
    <Swiper
    spaceBetween={50}
    slidesPerView={2.5}
    slidesOffsetAfter={100}
    slidesOffsetBefore={50}
    style={{marginBottom: '400px'}}
    >
      {tutorials
      .filter((tut) => tut.isActive)
      .sort((a, b) => a.LWid - b.LWid).map(tut => {
    
          return (
            <SwiperSlide key={tut.id} onClick={() => handleItemClick(tut)}
            style={{
              minWidth: '350px',
              maxWidth: '370px',
              border: '0px dotted blue'
            }}>
              <div className="tutorial-card">
                <img 
                src={tut.coverImage} 
                alt=" pack-cover" className="tutorial-cover"
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                  padding: 0,
                  margin: 0,
                }}
               />
                <p className="tutorial-title"
                style={{
                  width: '100%',
                  marginLeft: '1.5%',
                  border: '0px solid green',
                }}>{tut.title}</p>
              </div>
            </SwiperSlide>
          );
        
      })}
    </Swiper>
    <VideoModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        video={video}
        title={title}
        runtime={runtime}
        description={description}
        src={src}
      />
    
    </>
  );
}