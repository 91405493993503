import { useState } from "react";
import { IconButton } from "@material-ui/core";
import {ReactComponent as ThumbsdownIcon} from "../../assets/icons/thumbs-down-solid.svg";
import {ReactComponent as ThumbsupIcon} from "../../assets/icons/thumbs-up-solid.svg";

//styles
import './LingoCard.css'

export default function LingoCard({ 
    id, 
    doc, 
    sourceLang, 
    targetLang,
    onHandleAnswer,
    onLingoAudio,
  }) {
    
  const [isFlipped, setIsFlipped] = useState(false);
  
  const handleClick = async () => {
    setIsFlipped(!isFlipped);
    if(!isFlipped){
      onLingoAudio(doc.audioFile);
    }
  }

  return (
  
      <div className="card-container" onClick={handleClick}>
        <div className={`card ${isFlipped ? "flipped" : ""}`}>

            <div className="front">
              <span className="lingoCard-frontText">{doc[sourceLang]}</span>
            </div>
    
            {isFlipped && (
                

              <div className="back">

                {(doc.elp && targetLang === 'el') && <p className="phonetics">{doc.elp}</p>}
                {(doc.hip && targetLang === 'hi') && <p className="phonetics">{doc.hip}</p>}
                {(doc.rup && targetLang === 'ru') && <p className="phonetics">{doc.rup}</p>}
                {(doc.ukp && targetLang === 'uk') && <p className="phonetics">{doc.ukp}</p>}

                <span className="lingoCard-backText">{doc[targetLang]}</span>

                <div className="buttons" style={{
                  position: 'absolute',
                  bottom: '90px',
                  width: '100%',
                }}>

                    <ThumbsdownIcon
                      onClick={()=> onHandleAnswer(false)}
                      style={{
                        fill: 'var(--clr-secondary1)',
                        width: '3rem',
                        marginLeft: '20px',
                        marginTop: '10px',
                      }} />

                    <ThumbsupIcon
                      onClick={()=> onHandleAnswer(true)}
                      style={{
                        fill: 'var(--clr-success)',
                        width: '3rem',
                        marginRight: '20px',
                      }} />
                  </div>
              </div>)}
          </div>
        </div>

  );
}