import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide} from 'swiper/react'
// styles
import './Gallery.css'
import 'swiper/css'

export default function UserPacksCarousel({ userpacks }) {
  const [selectedPack, setSelectedPack] = useState("")
  const [currentPackTitle, setCurrentPackTitle] = useState("")
  const [packType, setPackType] = useState("user-packs")
  const history = useHistory();
  
  const handleClick = (pack) => {
    setPackType('user-packs');
    setSelectedPack(pack.id);
    setCurrentPackTitle(pack.title);
    history.push(`${packType}/${pack.id}`);
  }

  return (
    <>
    <Swiper
    spaceBetween={20}
    slidesPerView={2.5}
    slidesOffsetAfter={100}
    slidesOffsetBefore={50}
    >
      { userpacks ? userpacks.map(pack => {
      
          return (
          
            <SwiperSlide key={pack.id} onClick={() => {
              setSelectedPack(`${packType}/${pack.id}`);
                handleClick(pack);
              }}
              style={{
                width: '350px',
                minWidth: '380px',
                maxWidth: '450px',
                height: 'auto',
                border: '0px dotted red',
              }}>
          
                <div className="pack-card"> 
                  <img src={pack.coverImage} alt=" " className="pack-cover" />
                  <p className="pack-title">{pack.title}</p>
                </div>
          
            </SwiperSlide>
          
          );
      
      }) : null }
  
    </Swiper>

    </>
  
  );
}