import { useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { storage } from '../../firebase/config'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'

import { 
  ListItem, 
  ListItemText, 
  IconButton 
} from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import WarningDialog from '../../components/dialogs/WarningDialog';

export default function UserCardsList({ packId, searchQuery }) {
  const [open, setOpen] = useState(false)
  const [selectedPackId, setSelectedPackId] = useState("")
  const [selectedCardId, setSelectedCardId] = useState("")
  const [selectedCardAudio, setSelectedCardAudio] = useState("")
  const [cards, setCards] = useState([])
  const { user } = useAuthContext()
  const { documents, error } = useCollection(
    `users/${user.uid}/packs/${packId}/cards/`, null, ['createdAt', 'desc']
  )

  useEffect(() => {
    if (documents) {
      setCards(documents)
    }
  }, [documents]);

  const cleanPackName = (filename) => {
    const cleaned = filename.toLowerCase().replace(/[^\w\s\-éüäöß]/gi, '');
    return cleaned.replace(/\s+/g, '_');
  };

  function formatString(str) {
    // Replace commas, exclamation marks, and question marks with spaces
    const sanitized = str.replace(/[,!?\s]+/gi, ' ');
    // Convert to lowercase and replace spaces with underscores
    const cleaned = sanitized.toLowerCase().replace(/[^\p{L}\d]+/gu, '_').replace(/^_+|_+$/g, '');
    return cleaned;
  }
  
  const handlePlayAudioClip = async (audioFile) => {

    let audioRef = storage.ref(`user-audio/${audioFile}`);
  
    const audioUrl = await audioRef.getDownloadURL();
    const audio = new Audio(audioUrl);
  
    audio.play();
  };
  

  const handleDeleteCard = async (selectedCardId) => {
    try {
      await firebase.firestore().collection(`users/${user.uid}/packs/${packId}/cards/`)
        .doc(selectedCardId).delete()
      console.log('Pack successfully deleted!')
  
      // remove the deleted pack from userPacks
      setCards(cards.filter(card => card.id !== selectedCardId))
    } catch (error) {
      console.error('Error removing card: ', error)
    }
    setOpen(false)
  }

  //filters based upon searchQuery in Studio.js
  const filteredCards = cards.filter(
    (card) =>
      card.front.toLowerCase().includes(searchQuery.toLowerCase()) ||
      card.back.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  return (
    <>
        <ul className="userCardsList" style={{paddingBottom: '50px'}}>
          {cards.length === 0 && <p style={{color: 'var(--clr-text-dark'}}>No cards yet. Let's make some!</p>}
          {filteredCards.map((card) => (
            <ListItem key={card.id} className="userCardsListItem"
            sx={{
              background: 'var(--clr-text-light)',
              margin: '10px auto',
              width: '100%',
              minWidth: '350px',
              maxWidth: '600px',
              border: '1px solid #f2f2f2',
              boxShadow: '3px 3px 5px rgba(50,50,50,0.1)',
              padding: '20px',
              position: 'relative',
              overflow: 'hidden',
              borderLeft: '4px solid var(--clr-primary1)'
            }}
            >
              <ListItemText
                primary={<span
                  style={{ 
                    color: '#777',
                    fontSize: '1em'
                  }}
                >{card.front}</span>}
                secondary={
                  card.phonetics ? ( // check if phonetics is not empty
                    <span 
                      style={{
                        marginLeft: 'auto',
                        marginRight: '40px',
                        color: 'var(--clr-primary1)',
                        fontWeight: '600',
                        fontSize: '1.45em'
                      }}
                    >
                      <i style={{ 
                        fontWeight: 400,
                        fontSize: "1rem",
                        color: "var(--clr-primary1)",
                        }}>{card.phonetics}</i><br />
                      {card.back}
                    </span>
                  ) :
                    <span 
                    style={{
                      marginLeft: 'auto',
                      marginRight: '40px',
                      color: 'var(--clr-primary1)',
                      fontWeight: '600',
                      fontSize: '1.45em'
                    }}>{card.back}</span>
                }
              />

            <IconButton
              onClick={() => {
                setSelectedPackId(card.collection);
                setSelectedCardAudio(card.audioFile);
                handlePlayAudioClip(card.audioFile);
              }}
              edge="end"
              aria-label="play"
            >
              <PlayCircleIcon
                style={{
                  fontSize: "1.75rem",
                  fill: "var(--clr-success)",
                  marginTop: "2px",
                  marginRight: "2px",
                }}
              />
            </IconButton>

              <IconButton
                onClick={() => {
                  setSelectedCardId(card.id);
                  setOpen(true);
                }}
                edge="end"
                aria-label="delete"
              >
                <DeleteIcon sx={{ fontSize: "1.75rem", color: 'var(--clr-primary1)' }} />
              </IconButton>
            </ListItem>
        ))}
        </ul>

        <WarningDialog 
          open={open} 
          onClose={() => setOpen(false)} 
          title={`Delete Card: ${selectedCardId}`}
          message="Are you sure you want to delete this card?" 
          primaryButton={{ label: "Delete", onClick: () => {handleDeleteCard(selectedCardId)}}} 
          secondaryButton={{ label: "Cancel", onClick: () => setOpen(false) }} 
        />
    </>
  )
}
