import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { db } from "../../firebase/config";
import badWords from "bad-words";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  Select,
  MenuItem,
  ImageList,
  ImageListItem,
  TextField,
  IconButton,
} from "@mui/material";
import ToolTip from '@mui/material/Tooltip';

import firebase from "firebase/app";
import imageData from "../../data/ImageData";

//styles & img data

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import './CreatePack.css'
import { Form } from "formik/dist";

function CreatePack({
  imgPath, 
  packId, 
  targetLanguage,
  targetVoice,
  speechRate, 
  editPackSettings, 
  onClose,
  handleClose
}) {
  
  const history = useHistory();
  const { user } = useAuthContext();
  const [selectedImage, setSelectedImage] = useState(imgPath || "../../assets/images/alphabet.jpg");
  const [selectedLanguage, setSelectedLanguage] = useState(targetLanguage ||"es-ES");
  const [selectedVoice, setSelectedVoice] = useState(targetVoice || "");
  const [audioSrc, setAudioSrc] = useState("");
  const [voiceVarieties, setVoiceVarieties] = useState([]);
  const [selectedRate, setSelectedRate] = useState( speechRate || "1.0");
  const [filteredVoiceVarieties, setFilteredVoiceVarieties] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    coverImage: imgPath ||  "../../assets/images/alphabet.jpg",
    title: packId ? packId : "",
    targetLanguage: targetLanguage ? targetLanguage : "es-ES",
    targetVoice: targetVoice ? targetVoice : "",
    speechRate: speechRate ? speechRate : '1.0',
  };

  const validationSchema = Yup.object({
    coverImage: Yup.string().required("Cover image is required"),
    title: Yup.string().required("Title is required"),
    targetLanguage: Yup.string().required("Target language is required"),
    speechRate: Yup.string().required("Speaking rate is required"),
  });


  const handleSubmit = (values) => {
    // handle form submission here
    db.collection(`users/${user.uid}/packs`).doc(values.title).set({
      collection: values.title,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      coverImage: selectedImage ? selectedImage : "../../assets/images/alphabet.jpg",
      isActive: true,
      numbCards: 0,
      packType: "userPack",
      speechRate: selectedRate ? selectedRate : "1.0",
      targetLanguage: selectedLanguage,
      title: values.title,
      targetVoice: selectedVoice
    })
      .then(() => {
        console.log("New Pack saved to Firestore");
        localStorage.setItem("targetLanguage", selectedLanguage)
        localStorage.setItem("target", selectedLanguage.substring(0, 2))
        localStorage.setItem("targetVoice", selectedVoice)
        localStorage.setItem("speechRate", selectedRate)
        setSelectedImage(null);
        handleClose(); // reset voices field
        history.push(`/studio/${values.title}`)
      })
      .catch((error) => {
        console.error("Error saving to Firestore: ", error);
      });
  };

  const handleUpdatePack = (values) => {
    // handle form submission here
    db.collection(`users/${user.uid}/packs`).doc(packId).update({
      coverImage: selectedImage,
      targetLanguage: selectedLanguage,
      targetVoice: selectedVoice,
      speechRate: selectedRate,
    })
      .then(() => {
        localStorage.setItem("targetLanguage", selectedLanguage)
        localStorage.setItem("target", selectedLanguage.substring(0, 2))
        localStorage.setItem("targetVoice", selectedVoice)
        localStorage.setItem("speechRate", selectedRate)
        console.log("Pack updated in Firestore");
        onClose(); // reset voices field
      })
      .catch((error) => {
        console.error("Error saving to Firestore: ", error);
      });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const {
    values, 
    touched, 
    errors, 
    handleChange, 
    handleBlur, 
    setFieldValue
  } = formik;

  // fetch voice varieties
  useEffect(() => {
    async function fetchVoiceVarieties() {
      try {
        const response = await axios.get("../../data/voices.json");
        setVoiceVarieties(response.data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchVoiceVarieties();
  }, []);

   // update filtered voice varieties when selected language changes
   useEffect(() => {
    const filteredVarieties = voiceVarieties.filter(
      (voice) => voice.iso4 === selectedLanguage
    );
    setFilteredVoiceVarieties(filteredVarieties);
  }, [selectedLanguage, voiceVarieties]);

  // update audio source when selected voice changes
  useEffect(() => {
    if (selectedVoice) {
      const audioUrl = `../../assets/audio/${selectedVoice}.mp3`;
      setAudioSrc(audioUrl);
    }
  }, [selectedVoice]);

  useEffect(() => {
    switch (selectedLanguage) {
      case "el-GR":
        setSelectedVoice("el-GR-Wavenet-A");
        //localStorage.setItem("targetVoice", "el-GR-Wavenet-A");
        break;
    case "cs-CZ":
      setSelectedVoice(targetVoice ? targetVoice : "cs-CZ-Wavenet-A");
      //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
      break;
    case "zh-CN":
      setSelectedVoice(targetVoice ? targetVoice : "cmn-CN-Wavenet-A");
      //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
      break;
    case "da-DK":
      setSelectedVoice(targetVoice ? targetVoice : "da-DK-Wavenet-A");
      //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
      break;
      case "en-GB":
        setSelectedVoice(targetVoice ? targetVoice : "en-GB-Neural2-A");
        //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
        break;

      case "es-ES":
        setSelectedVoice(targetVoice ? targetVoice : "es-ES-Neural2-A");
        //localStorage.setItem("targetVoice", "es-ES-Neural2-A");
        break;
      case "fr-FR":
        setSelectedVoice(targetVoice ? targetVoice : "fr-FR-Neural2-A");
        //localStorage.setItem("targetVoice", "fr-FR-Neural2-A");
        break;
      case "de-DE":
        setSelectedVoice(targetVoice ? targetVoice : "de-DE-Neural2-B");
        //localStorage.setItem("targetVoice", "de-DE-Neural2-B");
        break;
      case "he-IL":
        setSelectedVoice(targetVoice ? targetVoice : "he-IL-Wavenet-A");
        //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
        break;
      case "it-IT":
        setSelectedVoice(targetVoice ? targetVoice : "it-IT-Neural2-A");
        //localStorage.setItem("targetVoice", "it-IT-Neural2-A");
        break;
      case "nl-NL":
        setSelectedVoice(targetVoice ? targetVoice : "nl-NL-Wavenet-A");
        //localStorage.setItem("targetVoice", "nl-NL-Wavenet-A");
        break;
      case "nb-NO":
        setSelectedVoice(targetVoice ? targetVoice : "nb-NO-Wavenet-A");
        //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
        break;
      case "pt-PT":
        setSelectedVoice(targetVoice ? targetVoice : "pt-PT-Wavenet-A");
        //localStorage.setItem("targetVoice", "pt-PT-Wavenet-A");
        break;
      case "pl-PL":
        setSelectedVoice(targetVoice ? targetVoice : "pl-PL-Wavenet-A");
        //localStorage.setItem("targetVoice", "pl-PL-Wavenet-A");
        break;
      case "ru-RU":
        setSelectedVoice(targetVoice ? targetVoice : "ru-RU-Wavenet-A");
        //localStorage.setItem("targetVoice", "pl-PL-Wavenet-A");
        break;
      case "sv-SE":
        setSelectedVoice(targetVoice ? targetVoice : "sv-SE-Wavenet-A");
        //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
        break;
      case "tr-TR":
        setSelectedVoice(targetVoice ? targetVoice : "tr-TR-Wavenet-A");
        //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
        break;
      case "uk-UA":
        setSelectedVoice(targetVoice ? targetVoice : "uk-UA-Wavenet-A");
        //localStorage.setItem("targetVoice", "pl-PL-Wavenet-A");
        break;
      default:
        setSelectedVoice(targetVoice ? targetVoice : "en-GB-Neural2-A");
        //localStorage.setItem("targetVoice", "en-GB-Neural2-A");
    }
  }, [selectedLanguage, targetVoice]);
  
  useEffect(() => {
    if (editPackSettings) {
      setSelectedImage(initialValues.coverImage);
      setSelectedLanguage(initialValues.targetLanguage);
      setSelectedVoice(initialValues.targetVoice);
    }
  }, [editPackSettings]);
  

  const handleSelectImage = (e, path) => {
    e.preventDefault();
    const pathToCoverImage = path;
    setSelectedImage(pathToCoverImage);
    // setImagePath(pathToCoverImage);
    localStorage.setItem('coverImage', pathToCoverImage);
  };

  function handleTitleBlur(event) {
    const filter = new badWords();
    const title = event.target.value;
    if(title === "") {
      formik.setFieldValue("title", "Untitled Pack");
      localStorage.setItem('title', "Untitled Pack");
    } else {
      const filtered = filter.clean(title);
      formik.setFieldValue("title", filtered);
      localStorage.setItem('title', filtered);
    }
  }

  const handleLanguageChange = (event) => {
    const targetValue = event.target.value;
    setSelectedLanguage(targetValue);
    //set selected voice to first in list
    // const filteredVarieties = voiceVarieties.filter(
    //   (voice) => voice.iso4 === targetValue
    // );
  
    //preload audio sample so plays immediately
    switch(targetValue) {
      case "en-GB":
        setSelectedVoice("en-GB-Neural2-A");
        localStorage.setItem("targetVoice", "en-GB-Neural2-A");
        break;
      case "zh-CN":
        setSelectedVoice("cmn-CN-Wavenet-A");
        localStorage.setItem("targetVoice", "cmn-CN-Wavenet-A");
        break;
      case "es-ES":
        setSelectedVoice("es-ES-Neural2-A");
        localStorage.setItem("targetVoice", "es-ES-Neural2-A");
        break;
      case "fr-FR":
        setSelectedVoice("fr-FR-Neural2-A");
        localStorage.setItem("targetVoice", "fr-FR-Neural2-A");
        break;
      case "cs-CZ":
        setSelectedVoice("da-DK-Wavenet-A");
        localStorage.setItem("targetVoice", "da-DK-Wavenet-A");
        break;
      case "da-DK":
        setSelectedVoice("da-DK-Wavenet-A");
        localStorage.setItem("targetVoice", "da-DK-Wavenet-A");
        break;
      case "de-DE":
        setSelectedVoice("de-DE-Neural2-B");
        localStorage.setItem("targetVoice", "de-DE-Neural2-B");
        break;
      case "he-IL":
        setSelectedVoice("he-IL-Wavenet-A");
        localStorage.setItem("targetVoice", "he-IL-Wavenet-A");
        break;
      case "hi-IN":
        setSelectedVoice("hi-IN-Neural2-A");
        localStorage.setItem("targetVoice", "hi-IN-Neural2-A");
        break;
      case "it-IT":
        setSelectedVoice("it-IT-Neural2-A");
        localStorage.setItem("targetVoice", "it-IT-Neural2-A");
        break;
      case "ko-KR":
        setSelectedVoice("ko-KR-Neural2-A");
        localStorage.setItem("targetVoice", "ko-KR-Neural2-A");
        break;
      case "nl-NL":
        setSelectedVoice("nl-NL-Wavenet-A");
        localStorage.setItem("targetVoice", "nl-NL-Wavenet-A");
        break;
      case "nb-NO":
        setSelectedVoice("nb-NO-Wavenet-A");
        localStorage.setItem("targetVoice", "nb-NO-Wavenet-A");
        break;
      case "pt-PT":
        setSelectedVoice("pt-PT-Wavenet-A");
        localStorage.setItem("targetVoice", "pt-PT-Wavenet-A");
        break;
      case "pl-PL":
        setSelectedVoice("pl-PL-Wavenet-A");
        localStorage.setItem("targetVoice", "pl-PL-Wavenet-A");
        break;
      case "el-GR":
        setSelectedVoice("el-GR-Wavenet-A");
        localStorage.setItem("targetVoice", "el-GR-Wavenet-A");
        break;
      case "ru-RU":
        setSelectedVoice("ru-RU-Wavenet-A");
        localStorage.setItem("targetVoice", "ru-RU-Wavenet-A");
        break;
      case "sv-SE":
        setSelectedVoice("sv-SE-Wavenet-A");
        localStorage.setItem("targetVoice", "sv-SE-Wavenet-A");
        break;
      case "tr-TR":
        setSelectedVoice("tr-TR-Wavenet-A");
        localStorage.setItem("targetVoice", "tr-TR-Wavenet-A");
        break;
      case "uk-UA":
        setSelectedVoice("uk-UA-Wavenet-A");
        localStorage.setItem("targetVoice", "uk-UA-Wavenet-A");
        break;
      default:
        setSelectedVoice("en-GB-Neural2-A");
        localStorage.setItem("targetVoice", "en-GB-Neural2-A");
      }
  };

  function handleVoiceChange(event) {
    const voiceValue = event.target.value;
    setSelectedVoice(voiceValue);
    localStorage.setItem('targetVoice', formik.voice);
    formik.setFieldValue('voice', voiceValue);
    formik.setTouched({ ...formik.touched, voice: true });
    //setAudioSrc(`../../assets/audio/${voiceValue}.mp3`);
    localStorage.setItem('targetVoice', voiceValue);
  }



  //sample text group 1

  const sampleText = {
    "cs-CZ": "Dobrý den, jak se máš?",
    "da-DK": "Hej, hvordan har du det?",
    "nl-NL": "Hallo, hoe gaat het met je?",
    "en-GB": "Hello, how are you?",
    "fr-FR": "Bonjour, comment allez-vous?",
    "de-DE": "Hallo, wie geht es dir?",
    "el-GR": "Γεια σου, πώς είσαι;",
    "he-IL": "שלום, איך אתה?",
    "hi-IN": "नमस्ते, तुम्हारा हाल कैसा है?",
    "it-IT": "Ciao, come stai?",
    "ko-KR": "안녕, 어떻게 지내?",
    "zh-CN": "你好，你好吗？",
    "nb-NO": "Hei, hvordan har du det?",
    "pl-PL": "Cześć, jak się masz?",
    "pt-PT": "Olá, como você está?",
    "ru-RU": "Привет, как дела?",
    "es-ES": "Hola, ¿cómo estás?",
    "sv-SE": "Hej, hur mår du?",
    "tr-TR": "Merhaba, nasılsın?",
    "uk-UA": "Привіт, як справи?",
  };

  //sample text group 2
  //use base sentence "I would like to reserve a table for two."
  const sampleText2 = {
    "cs-CZ": "Chtěl bych si rezervovat stůl pro dva.",
    "da-DK": "Jeg vil gerne reservere et bord til to.",
    "nl-NL": "Ik wil graag een tafel reserveren voor twee.",
    "en-GB": "I would like to reserve a table for two.",
    "fr-FR": "Je voudrais réserver une table pour deux.",
    "de-DE": "Ich möchte einen Tisch für zwei reservieren.",
    "el-GR": "Θα ήθελα να κάνω κράτηση για δύο άτομα.",
    "he-IL": "אני רוצה להזמין שולחן לשניים.",
    "hi-IN": "मैं दो लोगों के लिए एक मेज़ आरक्षित करना चाहूँगा।",
    "it-IT": "Vorrei prenotare un tavolo per due.",
    "ko-KR": "두 명을 위한 테이블을 예약하고 싶습니다.",
    "zh-CN": "我想预订一张两人桌。",
    "nb-NO": "Jeg vil gjerne reservere et bord for to.",
    "pl-PL": "Chciałbym zarezerwować stolik dla dwóch osób.",
    "pt-PT": "Gostaria de reservar uma mesa para dois.",
    "ru-RU": "Я хотел бы забронировать столик на двоих.",
    "es-ES": "Me gustaría reservar una mesa para dos.",
    "sv-SE": "Jag skulle vilja reservera ett bord för två.",
    "tr-TR": "İki kişilik bir masa ayırtmak istiyorum.",
    "uk-UA": "Я хотів би забронювати столик на двох.",
  };


  //play sample audio with selected language, voice, and rate
const handleSampleAudioPlay = async () => {
  setIsLoading(true);

  try {
    const synthesizeSpeech = firebase.functions().httpsCallable('synthesizeSpeech');
    const result = await synthesizeSpeech({
      text: sampleText[selectedVoice.substring(0, 5)],
      targetLanguage: selectedVoice.substring(0, 5),
      targetVoice: selectedVoice,
      speechRate: selectedRate
    });

    const audioSrc = `data:audio/mp3;base64,${result.data}`;
    const audio = new Audio(audioSrc);
    audio.play();
  } catch (error) {
    console.error('Error fetching audio sample:', error);
  } finally {
    setIsLoading(false);
  }
};


  function handleRateChange(event) {
    const rateValue = event.target.value;
    formik.setFieldValue('rate', rateValue);
    formik.setTouched({ ...formik.touched, rate: true });
    setSelectedRate(rateValue);
    console.log("selectedRate: ", rateValue);
    localStorage.setItem('rate', rateValue);
  }

  //render component
  return (
    <div className="create-pack-container">
      <div className="create-pack-form-header">
        {!editPackSettings && <h3>Create a New Pack</h3>}
        {editPackSettings && <h3>Update Pack</h3>}
      </div>
      <form className="create-pack-form" onSubmit={formik.handleSubmit}>
        <div className="form-container">
          <div className="left-col">

            <FormControl sx={{ m: 1, maxWidth: '100%', marginTop: '-2px'}}>
              <span className="new-pack-form-label">Cover Image*</span>
                <ImageList cols={3} rowHeight={60}
                  sx={{
                    alignSelf: 'left',
                        width: '100%',
                        maxHeight: 'calc(350px - 36px)', /* reduce the maxHeight to allow room for padding */
                        paddingBottom: '30px', /* add padding at the bottom */
                  }}
                >
                  {imageData.map((item, index) => (
                    <ToolTip title={item.country} placement="right">
                      <ImageListItem 
                          key={item.path} 
                          onClick={(e) => handleSelectImage(e, item.path)}
                          className={selectedImage === item.path ? "selected" : ""}>
                          <img
                            src={item.thumbnail}
                            alt={item.title}
                            value={item.path}
                            className={`${selectedImage === item.path ? "selectedImage" : ""} ${
                              selectedImage === item.path ? "selectedImageBorder" : ""
                            }`}/>
                            <div className="overlay"></div>
                        </ImageListItem>
                    </ToolTip>
                  ))}
                </ImageList>
              {errors.coverImage && touched.coverImage ? <div>{errors.coverImage}</div> : null}
            </FormControl>
          </div>
          <div className="right-col">

            <FormControl className="newPack-formControl">
            <span className="new-pack-form-label">Title*</span>
            <TextField
                id="title"
                placeholder="Enter a title..."
                className="form-text-input"
                name="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleTitleBlur}
                error={touched.title && Boolean(errors.title)}
                helpertext={touched.title && errors.title}
                inputProps={{
                  placeholder: 'Enter a title...',
                  'aria-label': 'Without label',
                  maxLength: 40,
                  style: {
                    fontSize: '1rem',
                    color: '#000',
                    border: '0px solid red',
                  },
                }}
                disabled={!!editPackSettings}
                InputLabelProps={{
                  style: { lineHeight: '1rem' },
                }}
                InputProps={{
                  style: { height: '40px', lineHeight: '1rem' },
                  classes: {
                    root: 'form-input-root',
                    input: 'form-input',
                  },
                }}
                FormHelperTextProps={{
                  classes: { root: 'form-helper-text', error: 'form-helper-text-error' },
                }}
                PaperProps={{
                  style: {
                    maxHeight: '120px',
                    marginTop: '8px',
                  },
                }}
              />
          </FormControl>
  
          <FormControl className="newPack-formControl">
          <span className="new-pack-form-label">Target Language*</span>
          <Select
              id="targetLanguage"
              name="targetLanguage"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              onBlur={handleBlur}
              error={touched.targetLanguage && Boolean(errors.targetLanguage)}
              helpertext={touched.targetLanguage && errors.targetLanguage}
              inputProps={{
                placeholder: 'Select a language...',
                'aria-label': 'Without label'
              }}
              displayEmpty
              style={{ height: '40px', lineHeight: '40px' }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '300px',
                    // transform: 'translateY(30px)',
                  },
                },
              }}
            >
              <MenuItem value="0-0"><em>Select a language...</em></MenuItem>
              <MenuItem value={'cs-CZ'}>Czech</MenuItem>
              <MenuItem value={'da-DK'}>Danish</MenuItem>
              <MenuItem value={'nl-NL'}>Dutch</MenuItem>
              <MenuItem value={'en-GB'}>English</MenuItem>
              <MenuItem value={'fr-FR'}>French</MenuItem>
              <MenuItem value={'de-DE'}>German</MenuItem>
              <MenuItem value={'el-GR'}>Greek</MenuItem>
              <MenuItem value={'he-IL'}>Hebrew</MenuItem>
              <MenuItem value={'hi-IN'}>Hindi</MenuItem>
              <MenuItem value={'it-IT'}>Italian</MenuItem>
              <MenuItem value={'ko-KR'}>Korean</MenuItem>
              <MenuItem value={'zh-CN'}>Mandarin</MenuItem>
              <MenuItem value={'nb-NO'}>Norwegian</MenuItem>
              <MenuItem value={'pl-PL'}>Polish</MenuItem>
              <MenuItem value={'pt-PT'}>Portuguese</MenuItem>
              <MenuItem value={'ru-RU'}>Russian</MenuItem>
              <MenuItem value={'es-ES'}>Spanish</MenuItem>
              <MenuItem value={'sv-SE'}>Swedish</MenuItem>
              <MenuItem value={'tr-TR'}>Turkish</MenuItem>
              <MenuItem value={'uk-UA'}>Ukrainian</MenuItem>
            </Select>
          </FormControl>
      
          <FormControl className="newPack-formControl">
          
          <span className="new-pack-form-label">Target Voice*</span>
            <select
                id="voice"
                name="voice"
                disabled = {!selectedLanguage}
                value={selectedVoice}
                onChange={(event) => {
                  setSelectedVoice(event.target.value);
                  setFieldValue('targetVoice', event.target.value);
                }}
                className="voice-dropdown"
                style={{ 
                fontSize: '1rem',
                paddingLeft: '10px',
                height: '40px', 
                lineHeight: '40px', 
                width: '100%', 
                background: 'transparent',
                border: '1px solid var(--clr-text-medium)',
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: '300px',
                      marginTop: '20px'
                    },
                  },
                }}
                >
                  <option value="">Select a Voice</option>
                {filteredVoiceVarieties.map((voiceVariety) => (
                  <option
                    key={voiceVariety.description}
                    value={voiceVariety.description}
                    className="voice-option"
                    style={{width: '100%'}}
                  >
                    {voiceVariety.description}
                  </option>
                ))}
              </select>

        </FormControl>      
              
        <FormControl className="newPack-formControl">
        <span className="new-pack-form-label">Speech Rate*</span>
          <Select
              id="speechRate"
              name="speechRate"
              value={selectedRate}
              onChange={handleRateChange}
              onBlur={handleBlur}
              error={touched.speechRate && Boolean(errors.speechRate)}
              helpertext={touched.speechRate && errors.speechRate}
              inputProps={{
                placeholder: 'Select a rate...',
                'aria-label': 'Without label',
              }}
              displayEmpty
              style={{ height: '40px', lineHeight: '40px', marginTop: '4px'}}
              MenuProps={{
                PaperProps: {
                  style: {
                    border: '1px solid #ccc',
                    maxHeight: '300px',
                    // transform: 'translateY(30px)',
                  },
                },
              }}
            >
              <MenuItem value=""><em>Select a speaking rate...</em></MenuItem>
              <MenuItem value={'0.5'}>Slow</MenuItem>
              <MenuItem value={'0.8'}>Slightly Slower</MenuItem>
              <MenuItem value={'1.0'}>Normal</MenuItem>
              <MenuItem value={'1.25'}>Slightly Faster</MenuItem>
              <MenuItem value={'1.5'}>Fast</MenuItem>
            </Select> 
        
        </FormControl>

        <FormControl className="newPack-formControl">
          {!isLoading && <IconButton
              onClick={handleSampleAudioPlay}
              aria-label="Test Audio"
              style={{
                color: 'white',
                backgroundColor: 'var(--clr-tertiary)', // Set your theme color here
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
                width: '90%',
                height: '40px',
                marginTop: '15px',
                marginLeft: '5%',
                marginBottom: '10px',
                padding: '0 10px',
                borderRadius: '5px',
              }}
              className="test-audio-button"
            >
              <PlayCircleIcon
                style={{
                  fontSize: '1.5rem',
                  marginRight: '0.5rem',
                  color: 'var(--clr-text-light)', // Set your success color here
                }}
              />
              Test Audio
          </IconButton>}

          {isLoading && <IconButton
              onClick={handleSampleAudioPlay}
              aria-label="Test Audio"
              style={{
                color: 'white',
                backgroundColor: 'var(--clr-tertiary)', // Set your theme color here
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
                width: '90%',
                height: '40px',
                marginTop: '15px',
                marginLeft: '5%',
                marginBottom: '10px',
                padding: '0 10px',
                borderRadius: '5px',
                opacity: '0.5'
              }}
              className="test-audio-button"
            >
              <PlayCircleIcon
                style={{
                  fontSize: '1.5rem',
                  marginRight: '0.5rem',
                  color: 'var(--clr-text-light)', // Set your success color here
                }}
              />
              Loading...
          </IconButton>}
          
        </FormControl>
              
          </div>
      
        </div>
        {!editPackSettings && <button
          className="submit-form-button"
          type="submit">Create Pack</button>}

        {editPackSettings && <button
          className="submit-form-button"
          type="button" 
          onClick={handleUpdatePack}>
            Update Pack
        </button>}
      </form>
    </div>


  );
}

export default CreatePack