import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCollection } from '../../hooks/useCollection';
import { useAuthContext } from '../../hooks/useAuthContext'
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '../../firebase/config';
import { storage } from '../../firebase/config';
// import  useCardArray  from '../../hooks/useCardArray';
import AudioPopover from '../../components/AudioPopover';
import arrayShuffle from 'array-shuffle';
//cards
import LingoCard from '../../components/cards/LingoCard';
import TextCard from '../../components/cards/TextCard';
import ImageCard from '../../components/cards/ImageCard';
import BannerCard from '../../components/cards/BannerCard';
//styles & sounds
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Deck.css';
import rightAnswerSnd from '../../assets/snd/rightAnswer.wav';
import wrongAnswerSnd from '../../assets/snd/wrongAnswer.wav';
//dialogs
import QuizDialog from '../../components/dialogs/QuizDialog';
import PresentScoreDialog from '../../components/dialogs/PresentScoreDialog';



export default function Deck({
  LWid,
  doc,
  onRightAnswer,
  onWrongAnswer,
}) {
  const { user } = useAuthContext();
  const userName = user.displayName;
  const rightSnd = new Audio(rightAnswerSnd);
  const wrongSnd = new Audio(wrongAnswerSnd);
  const { id } = useParams();
  const formattedId = id.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
  let title = formattedId;
  title === "Us Capitols" ? title = "US Capitols" : title = formattedId;
  const { packType } = useParams();

  //We need to fetch docs from a different collection depending on the packType
  const lwPackUrl = `${packType}/${id}/cards`;

  //lingoPack URL?

  const userPackUrl = `users/${user.uid}/packs/${id}/cards`;
  const url = packType === 'user-packs' ? userPackUrl : lwPackUrl;
  const { documents } = useCollection(url);
  const [totalCards, setTotalCards] = useState(0);
  const [currentDocIndex, setCurrentDocIndex] = useState(1);
  const [shuffledDocs, setShuffledDocs] = useState([]);

  const[rightArray, setRightArray] = useState([]);
  const[wrongArray, setWrongArray] = useState([]);
  const [quizTitle, setQuizTitle] = useState('Pop Quiz!');
  const [quizSubTitle, setQuizSubTitle] = useState('Please select the number of cards in this quiz.');
  const [quizOptions, setQuizOptions] = useState([
    { value: 10, label: '10 questions' },
    { value: 20, label: '20 questions' },
  ]);
  const [primaryButtonLabel, setPrimaryButtonLabel] = useState('Let\'s Go!');
  const [secondaryButtonLabel, setSecondaryButtonLabel] = useState('Cancel');
  const [mode, setMode] = useState('studyMode');
  const [quizDialogOpen, setQuizDialogOpen] = useState(false);
  const [quizSize, setQuizSize] = useState(10);
  const [score, setScore] = useState(0);
  const [presentScoreDialogOpen, setPresentScoreDialogOpen] = useState(false);

  //create popup to gather target language before display
  const sourceLang = localStorage.getItem('sourceLang');
  const targetLang = localStorage.getItem('targetLang');  
  const [numbRight, setNumbRight] = useState(0);
  const [numbWrong, setNumbWrong] = useState(0);
  const [isRight, setIsRight] = useState(false);

  //audio and sound effects
  const [audioOn, setAudioOn] = useState(true);
  const [soundEffectsOn, setSoundEffectsOn] = useState(true);
  const [isExploding, setIsExploding] = useState(false);
  const [lastCardIsRight, setLastCardIsRight] = useState(false);

  //audio paths based upon packType (lingo or user-packs)
  const baseAudioPath =  'audio/' + id + '/';
  const baseUserAudioPath = 'user-audio'
  
  async function playLingoAudio(cardType, audioFile) {
  
    const ext = '.mp3';
    //const formattedString = formatString(audioFile) + '.mp3';

    if((packType === 'full-packs' || packType === "snack-packs") && cardType === 'lingoCard') {
      let audioRef = storage.ref(baseAudioPath  + targetLang + '/' + audioFile + ext);
      console.log('audioRef: ' , audioRef)
      const audioUrl = await audioRef.getDownloadURL();
      const audio = new Audio(audioUrl);
      if(audioOn){
        audio.play();
      }
    } else if (packType === 'user-packs') {
        let audioRef = storage.ref(baseUserAudioPath + '/' + audioFile);
        const audioUrl = await audioRef.getDownloadURL();
        const audio = new Audio(audioUrl);
        if(audioOn){
          audio.play();
        }
    } else {
        let audioRef = storage.ref(baseAudioPath + audioFile + ext);
        const audioUrl = await audioRef.getDownloadURL();
        const audio = new Audio(audioUrl);
        if(audioOn){
          audio.play();
        }
    }
  
  }

  function handleAudioToggle() {
    setAudioOn(!audioOn);
  }

  function handleSoundEffectsToggle() {
    setSoundEffectsOn(!soundEffectsOn);
  }

  const handleButtonClick = (m) => {
    let mode = m;
    if(mode === 'rolloverMode'){
    let msg = "Rollover Mode"
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
      hideIcon: true,
      style: {
        backgroundColor: '#00b300',
        textColor: '#fff',
        fontWeight: '500'
      }
    });
    } else if (mode ==='quizMode') {
      let msg = "Quiz Mode"
      toast.success(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideIcon: true,
        style: {
          backgroundColor: 'var(--clr-secondary1)',
          textColor: '#fff',
          fontWeight: '500'
        }
      });
    } else if (mode ==='studyMode') {
      let msg = "Study Mode"
      toast.dark(msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
        hideIcon: true,
        style: {
          backgroundColor: 'var(--clr-primary1)',
          textColor: '#fff',
          fontWeight: '500'
        }
      });
    } else {
      console.log('current mode', mode)
    }
  }

  useEffect(() => {
    if (documents) {
      console.log('DOCUMENTS: ', documents);
      const shuffled = arrayShuffle(documents);
      setShuffledDocs(shuffled);
      setTotalCards(shuffled.length);
      localStorage.setItem('cards', JSON.stringify(shuffled));
    }
  }, [documents]);

  if (!documents) {
    return <p>Loading...</p>;
  }
  if(!shuffledDocs){
    return console.log("shuffle something");
  }

  function handleAnswer(isRight, doc, id) {
    console.log('handleAnswer called')
    console.log('isRight: ', isRight, ' doc: ', doc)

    
    if(isRight) {
      if (currentDocIndex + 1 === totalCards) {
        setLastCardIsRight(true);
      } 
      setNumbRight(prevNumbRight => numbRight + 1);
      setRightArray(prevRightArray => [...rightArray, doc]);
      localStorage.setItem('rightArr', JSON.stringify([...rightArray, doc]));
    } else if (!isRight) {
      console.log('Adding 1 to the wrong count')
      setNumbWrong(prevNumbWrong => numbWrong + 1);
      setWrongArray(prevWrongArray => [...wrongArray, doc]);
      console.log('wrongArray: ', wrongArray)
      localStorage.setItem('wrongArr', JSON.stringify([...wrongArray, doc]));
    }
  
    setIsRight(isRight);

    //splice card from array
    shuffledDocs.splice(id, 1);
    
    //increment card count
    if(currentDocIndex >= totalCards){
      setCurrentDocIndex(totalCards);
    } else {  
      setCurrentDocIndex(currentDocIndex + 1);
    }
    //play card audio
    if (soundEffectsOn) {
      isRight ? rightSnd.play() : wrongSnd.play();
    }
    //if all cards have been answered, trigger rollover
    if(shuffledDocs.length === 0 && mode === 'quizMode'){ //pack empty and quizMode
      handleQuizStart();
    } 
    if (mode !== 'quizMode' && shuffledDocs.length === 0 && wrongArray.length > 0) { //pack empty and wrongArray has cards
      setMode('rolloverMode')
      handleRollover();
    } else if(mode === 'quizMode' && shuffledDocs.length === 0){ //end of quiz
      handleScoreQuiz();
      setMode('studyMode')
    } else if (shuffledDocs.length === 0 && wrongArray.length === 0) { //pack empty and wrongArray empty
      handleQuizSetup();
      setMode('quizMode')
    }
  }

  function handleRollover() {
    setMode('rolloverMode')
    setTotalCards(wrongArray.length + 1);
    setNumbRight(0);
    setNumbWrong(0);
    setCurrentDocIndex(1);
    setShuffledDocs([...wrongArray]);
    setWrongArray([]);
  }

   function handleQuizNoThanks(){
    resetDeck();
    setQuizDialogOpen(false);
  }

  function handleQuizSetup(){
    setScore(0);
    setMode('quizMode')
    //check if finished deck && and present quizSize options
    if(packType === 'userPack' && (currentDocIndex === totalCards) && (totalCards < 10)){
      setQuizTitle(`Congrats ${userName}!`)
      setQuizSubTitle('You\'ve completed the deck - time to make some more cards!')
      setSecondaryButtonLabel('OK')
      setQuizOptions([]);
      //setSecondaryButtonLabel('cancel')
    } else if (mode === 'rolloverMode' && (currentDocIndex === totalCards) && (totalCards >= 10 || totalCards >= 20 || totalCards >= 30)) {
      setQuizTitle(`Congrats ${userName}!`)
      setQuizSubTitle('How about a short quiz?')
      setPrimaryButtonLabel('Let\'s Go!')
      setSecondaryButtonLabel('No Thanks')
      setQuizOptions(
        totalCards >= 10
          ? [{ value: totalCards, label: 'Use all ' + totalCards + ' cards' }]
          : totalCards >= 20
          ? [
              { value: 10, label: '10 questions' },
              { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
            ]
          : totalCards >= 30
          ? [
              { value: 10, label: '10 questions' },
              { value: 20, label: '20 questions' },
              { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
            ]
          : []
      );
    }  else if ((currentDocIndex === totalCards) && (totalCards >= 10 && totalCards < 20)) {
      setQuizTitle(`Congrats ${userName}!`)
      setQuizSubTitle(`How about a short quiz?`)
      setPrimaryButtonLabel('Let\'s Go!')
      setSecondaryButtonLabel('No Thanks')
      setQuizOptions([
        { value: 10, label: '10 questions' },
        { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
      ]);
    } else if ((currentDocIndex === totalCards) && (totalCards >= 20 && totalCards < 30)) {
      setQuizTitle(`Congrats ${userName}!`)
      setQuizSubTitle('How about a short quiz?')
      setPrimaryButtonLabel('Let\'s Go!')
      setSecondaryButtonLabel('No Thanks')
      setQuizOptions([
        { value: 10, label: '10 questions' },
        { value: 20, label: '20 questions' },
        { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
      ]);
    } else if ((currentDocIndex === totalCards) && (totalCards >= 30)) {
      setQuizTitle(`Congrats ${userName}!`)
      setQuizSubTitle('How about a short quiz?')
      setPrimaryButtonLabel('Let\'s Go!')
      setSecondaryButtonLabel('No Thanks')
      setQuizOptions([
        { value: 10, label: '10 questions' },
        { value: 20, label: '20 questions' },
        { value: 30, label: '30 questions' },
      ]);
    }
    //************************************************************* */
    // User has not finished deck and has clicked pop quiz button
    if ((currentDocIndex !== totalCards) && (totalCards === 10 || totalCards === 20 || totalCards === 30)) {
      setQuizTitle(`Pop Quiz`)
      setQuizSubTitle(`Hurray! We've got 10 cards - the minimum for a quiz. Ready? Here we go...`)
      setPrimaryButtonLabel('Let\'s go!')
      setSecondaryButtonLabel('Cancel')
      setQuizOptions(
        totalCards === 10
          ? [{ value: totalCards, label: 'Use all ' + totalCards + ' cards' }]
          : totalCards === 20
          ? [
              { value: 10, label: '10 questions' },
              { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
            ]
          : totalCards === 30
          ? [
              { value: 10, label: '10 questions' },
              { value: 20, label: '20 questions' },
              { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
            ]
          : []
      );
    } else if ((currentDocIndex !== totalCards) && (totalCards > 10 && totalCards < 20)) {
      setQuizTitle('Pop Quiz')
      setQuizSubTitle(`Please select the number of questions`)
      setPrimaryButtonLabel('Let\'s do this!')
      setSecondaryButtonLabel('Cancel')
      setQuizOptions([
        { value: 10, label: '10 questions'},
        { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
      ]);
    } else if ((currentDocIndex !== totalCards) && (totalCards > 20 && totalCards < 30)) {
      setQuizTitle('Pop Quiz')
      setQuizSubTitle(`Please select the number of questions`)
      setPrimaryButtonLabel('Let\'s Go!')
      setSecondaryButtonLabel('Cancel')
      setQuizOptions([
        { value: 10, label: '10 questions' },
        { value: 20, label: '20 questions' },
        { value: totalCards, label: 'Use all ' + totalCards + ' cards' },
      ]);
    } else if ((currentDocIndex !== totalCards) && (totalCards >= 30)) {
      setQuizTitle('Pop Quiz')
      setQuizSubTitle('Please select the number of questions')
      setPrimaryButtonLabel('Let\'s Go!')
      setSecondaryButtonLabel('Cancel')
      setQuizOptions([
        { value: 10, label: '10 questions' },
        { value: 20, label: '20 questions' },
        { value: 30, label: '30 questions' },
      ]);
    }
    setQuizDialogOpen(true);
  }

  function handleQuizStart(quizSize) {
    setNumbRight(0);
    setNumbWrong(0);
    setScore(0);
    setCurrentDocIndex(1);
    setRightArray([]);
    setWrongArray([]);
    
    //const shuffledDocs = [...rightArray, ...wrongArray];
    const quizDocs = shuffledDocs.slice(0, quizSize);
    setShuffledDocs(quizDocs);
    setTotalCards(quizDocs.length);
    setQuizDialogOpen(false); // close the quiz dialog
  }

   function handleScoreQuiz(){
    if(lastCardIsRight){
      const score = (Math.ceil((totalCards - numbWrong) / totalCards * 100));
      console.log('score', score)
      setScore(score);
      //save to db
    db.collection(`users/${user.uid}/scores`).add({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      pack: title,
      score: score
    })
    .then(() => {
      console.log('Score of ' + score +'% saved successfully!');
    })
    .catch((error) => {
      console.error('Error saving score: ', error);
    });

    score >= 70 ? setIsExploding(true) : setIsExploding(false);
    setQuizDialogOpen(false);
    setPresentScoreDialogOpen(true);
    resetDeck()
   } else if (!lastCardIsRight) {
      const score = (Math.ceil((totalCards - (numbWrong + 1)) / totalCards * 100));
      console.log('score', score)
      setScore(score);
      //save to db
      db.collection(`users/${user.uid}/scores`).add({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        pack: title,
        score: score
      })
      .then(() => {
        console.log('Score of ' + score +'% saved successfully!');
      })
      .catch((error) => {
        console.error('Error saving score: ', error);
      });

      score >= 70 ? setIsExploding(true) : setIsExploding(false);
      setQuizDialogOpen(false);
      setPresentScoreDialogOpen(true);
      resetDeck()
   }
   
  }

  function resetDeck(){
    setShuffledDocs([...documents]);
    setTotalCards(documents.length);
    setNumbRight(0);
    setNumbWrong(0);
    setCurrentDocIndex(1);
    setRightArray([]);
    setWrongArray([]);
    setMode('studyMode')
    setLastCardIsRight(false);
  }
  
  return (
    <>
      <ToastContainer mode={mode} />
    
    <div className="deckNav"
    style={{ backgroundColor: mode === 'rolloverMode' || mode === 'quizMode' ? (mode === 'rolloverMode' ? 'var(--clr-success)' : 'var(--clr-secondary1)') : 'var(--clr-tertiary)' }}
    >
            <p className="deckNav-content">{title}: </p>
            <p className="deckNav-content">
            {currentDocIndex} of {totalCards}</p>
        
          <AudioPopover
            audioOn={audioOn}
            soundEffectsOn={soundEffectsOn}
            handleAudioToggle={handleAudioToggle}
            handleSoundEffectsToggle={handleSoundEffectsToggle}
          />
    </div>

    <div className="deck-content-container">

        <div className='score-container'>
              <div className="scoreComp">
                <span className="score-label">DON'T KNOWS</span>
                <h1 style={{color: 'var(--clr-primary1)'}}>{numbWrong}</h1>
              </div>
              <div className="scoreComp">
                <span className="score-label"> KNOWS</span>
                <h1 style={{color: 'var(--clr-primary1)'}}>{numbRight}</h1>
              </div>
        </div>
        
        <div className="deck-container">
          
          {shuffledDocs.map((doc, id) => {
            console.log('doc: ', doc);
            console.log('id: ', id)
            console.log('doc.id: ', doc.id)
            if (doc.cardType === 'lingoCard') {
              return (
                <LingoCard
                key={id}
                doc={doc}
                sourceLang={sourceLang}
                targetLang={targetLang}
                isRight={isRight} // pass the current value of isRight to LingoCard
                onHandleAnswer={(isRight) => handleAnswer(isRight, doc,id)} // pass isRight as a parameter to handleAnswer
                onLingoAudio={() => playLingoAudio(doc.cardType, doc.audioFile)}
              />
              
              );
            } else if (doc.cardType === 'textCard') {
              return (
                <TextCard 
                  key={id} 
                  doc={doc} 
                  front="front" 
                  back="back" 
                  phonetics={doc.phonetics}
                  onHandleAnswer={(isRight) => handleAnswer(isRight, doc, id)}
                  onLingoAudio={() => playLingoAudio(doc.cardType, doc.audioFile)}
                />
              );
            } else if (doc.cardType === 'bannerCard') {
              return (
                <BannerCard 
                  key={id} 
                  doc={doc} 
                  front="front" 
                  back="back"
                  onHandleAnswer={(isRight) => handleAnswer(isRight, doc, id)}
                  onLingoAudio={() => playLingoAudio(doc.cardType, doc.audioFile)}
                />
                
              );
            } else if (doc.cardType === 'imageCard') {
              return (
                <ImageCard 
                key={id} 
                doc={doc} 
                front="front" 
                back="back" 
                onHandleAnswer={(isRight) => handleAnswer(isRight, doc, id)}
                onLingoAudio={() => playLingoAudio(doc.cardType, doc.audioFile)}
                />
              );
            } else {
              return null
            }
          })}
          
        </div>

        {(mode !== "quizMode" && totalCards >= 10) && <button className="btn-quiz" onClick={handleQuizSetup}>Pop Quiz</button>}

        {mode === "quizMode" && <button className="btn-quiz" onClick={resetDeck}>Cancel Quiz</button>}
      </div>

      <QuizDialog
        open={quizDialogOpen}
        quizTitle={quizTitle}
        quizSubTitle={quizSubTitle}
        quizOptions={quizOptions}
        onClose={handleQuizNoThanks}
        onOk={handleQuizStart}
        value={quizSize}
        userName = {userName}
        totalCards = {totalCards}
        primaryButtonLabel={primaryButtonLabel}
        secondaryButtonLabel={secondaryButtonLabel}
        onChange={(event) => setQuizSize(Number(event.target.value))}
      />
      <div className="present-score-container" style={{
        marginTop: '-20px!important',
        border: '0px dotted red'
      }}>
        <PresentScoreDialog
            open={presentScoreDialogOpen}
            title="Present Score"
            score={score}
            onClose={() => setPresentScoreDialogOpen(false)}
            audioOn={audioOn}
            isExploding={isExploding}
        />
     </div>
    </>
  );
}