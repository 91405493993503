import * as React from 'react'
import { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  TextField,
  IconButton,
}
from '@mui/material';

import firebase from 'firebase/app'
import 'firebase/firestore'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useHistory } from 'react-router-dom';
import WarningDialog from '../../components/dialogs/WarningDialog';
import CreatePackModal from '../../components/studio/CreatePackModal';
//css & icons
import "./Gallery.css";
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function UserPacksList() {
  const { user } = useAuthContext()
  const [open, setOpen] = useState(false)
  const [selectedPackId, setSelectedPackId] = useState("")
  const [currentPackTitle, setCurrentPackTitle] = useState("")
  const [packType, setPackType] = useState("user-packs")
  // const [userPacks, setUserPacks] = useState([])
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();

  const [userpacks, setUserPacks] = useState([]);
  const [uerror, setUError] = useState(null);

  const hasSearchValue = Boolean(searchValue);

  useEffect(() => {
    const db = firebase.firestore();
    const packsRef = db.collection(`users/${user.uid}/packs`, null, ["createdAt", "desc"]);

    const unsubscribe = packsRef.onSnapshot((snapshot) => {
      const newPacks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setUserPacks(newPacks);
    }, (error) => {
      setUError(error);
    });

    return unsubscribe;
  }, [user.uid]);

  const handleClick = (pack) => {
    setPackType('user-packs');
    setSelectedPackId(pack.id);
    setCurrentPackTitle(pack.title);
    history.push(`${packType}/${pack.id}`);
  }

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  }

  const handleClearSearch = () => {
    setSearchValue('');
  };

  const handleDeletePack = async (selectedPackId) => {
    try {
      await firebase.firestore().collection(`users/${user.uid}/packs/`)
        .doc(selectedPackId).delete()
      console.log('Pack successfully deleted!')
  
      // remove the deleted pack from userPacks
      setUserPacks(userpacks.filter(pack => pack.id !== selectedPackId))
    } catch (error) {
      console.error('Error removing pack: ', error)
    }
    setOpen(false)
  }

  function handleEditPack(pack) {
    setSelectedPackId(pack.id);
    localStorage.setItem("targetLanguage", pack.targetLanguage)
    localStorage.setItem("target", pack.targetLanguage.substring(0, 2))
    localStorage.setItem("targetVoice", pack.targetVoice)
    localStorage.setItem("rate", pack.speechRate)
    history.push(`/studio/${pack.id}`);
  }

  return (
    <>
    <div className="listNav">
      <p className="listNavTitle">My Packs</p>

      <div style={{
        marginTop: '14px',
      }}>
          <CreatePackModal />
      </div>
      
      <TextField
      value={searchValue}
      onChange={handleSearchChange}
      placeholder="Search"
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        endAdornment: hasSearchValue ? (
          <ClearIcon sx={{ ml: 1, cursor: 'pointer' }} onClick={handleClearSearch} />
        ) : (
          <SearchIcon sx={{ ml: 1, mr: -1 }} />
        ),
        sx: {
          position: 'absolute',
          marginTop: '10px',
          right: '40px',
          width: '40%',
          borderRadius: '4px',
          backgroundColor: 'white',
          boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.3)',
          '& .MuiInputBase-input': {
            py: '10px',
            px: '10px',
            transition: 'none',
          },
          '&:hover': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          },
          '&.Mui-focused': {
            boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
          },
        },
      }}
    />
    </div>

      <List className="packs-list">
      
      {userpacks
            .filter(pack => pack.title.toLowerCase().includes(searchValue.toLowerCase()))
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .reverse()
            .map((pack) => {
  
          return (
            <React.Fragment   key={pack.id}>
            
              <ListItem className="pack">
              
                <ListItemAvatar
                  className="avatarContainer"
                  onClick={() => {handleClick(pack)
                }}>
                  <img
                    src={pack.coverImage}
                    alt=" "
                    className="pack-thumb"
                  />
                </ListItemAvatar>  

                <ListItemText 
                  primary={pack.title} 
                  secondary={`Total cards:  ${pack.numbCards}`}
                  onClick={() => {handleClick(pack)}}
                  className='list-item-text'
                />
              
                <IconButton 
                className="edit-pack-button"
                onClick={() => {
                  handleEditPack(pack);
                }}
                >
                  <EditIcon 
                  style={{
                    fontSize: '2rem',
                    color: 'var(--clr-primary1)',
                    marginRight: '6px',
                    border: '0px solid blue',
                  }}/>
                </IconButton>
                <IconButton 
                className="delete-pack-button"
                onClick={() => {
                  setSelectedPackId(pack.id);
                  setOpen(true)
                }}
                >
                  <DeleteIcon 
                  style={{
                    fontSize: '2rem',
                    color: 'var(--clr-primary1)',
                    marginRight: '6px',
                    border: '0px solid blue',
                  }}
                  />
                </IconButton>
                
              </ListItem>

              <Divider variant="inset" component="li" />
            </React.Fragment>
          )
        
        })}
      </List>
      <WarningDialog 
          open={open} 
          onClose={() => setOpen(false)} 
          title={`Delete Pack: ${selectedPackId}`}
          message="Are you sure you want to delete this pack?" 
          primaryButton={{ label: "Delete", onClick: () => {handleDeletePack(selectedPackId)}}} 
          secondaryButton={{ label: "Cancel", onClick: () => setOpen(false) }} 
        />
    </>
  )
}
