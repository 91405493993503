import React, { useState, useEffect } from 'react';
import CreatePackModal from '../../components/studio/CreatePackModal';
import WarningDialog from '../../components/dialogs/WarningDialog';
import CreatePack from '../../components/studio/CreatePack';
import {
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    Divider,
    Typography,
    Modal,
  }
  from '@mui/material';
//styles
import './SideDrawer.css';

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';

//firebase 
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useHistory, useLocation, useParams } from 'react-router-dom';

const drawerWidth = 400;


export default function SideDrawer({onNumbCardsUpdate, ...props}) {
  const handleOpen = props.handleOpen;
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const packId = useParams().id;

  const [userPacks, setUserPacks] = useState([]);
  const [uerror, setUError] = useState(null);
  const { user } = useAuthContext()

  const [editPackSettings, setEditPackSettings] = useState(false)
  const [selectedPackId, setSelectedPackId] = useState(null);
  const [selectedImage, setSelectedImage] = useState("");
  const [targetLanguage, setTargetLanguage] = useState(null);
  const [targetVoice, setTargetVoice] = useState("");
  const [speechRate, setSpeechRate] = useState("");
  const [searchValue, setSearchValue] = useState('');
  const hasSearchValue = Boolean(searchValue);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const db = firebase.firestore();
    const packsRef = db.collection(`users/${user.uid}/packs`).orderBy('createdAt', 'desc');

    const unsubscribe = packsRef.onSnapshot((snapshot) => {
      const newPacks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setUserPacks(newPacks);
    }, (error) => {
      setUError(error);
    });

    return unsubscribe;
  }, [user.uid]);

  useEffect(() => {
    const pack = userPacks.find(pack => pack.id === packId);

    if (pack && pack.numbCards) {
      onNumbCardsUpdate(pack.numbCards);
    }
  }, [userPacks, packId, onNumbCardsUpdate]);

  useEffect(() => {
    if (location.pathname.endsWith("/studio")) {
      props.setIsOpen(true);
      //history.push(location.pathname);
    } else {
      props.setIsOpen(false);
      //history.push(location.pathname);
    }
  }, [history]);


  const handlePackClick = (pack) => {
    setSelectedPackId(pack.id)
    localStorage.setItem("coverImage", pack.coverImage)
    localStorage.setItem("target", pack.targetLanguage.substring(0, 2))
    localStorage.setItem("targetLanguage", pack.targetLanguage)
    localStorage.setItem("targetVoice", pack.targetVoice)
    localStorage.setItem("speechRate", pack.speechRate)
    console.log('you selected: ', selectedPackId)
    props.setIsOpen(false)
    history.push(`/studio/${pack.id}`);
  };

  const handleDeletePack = async (selectedPackId) => {
    try {
      const db = firebase.firestore();
      const batch = db.batch();
  
      const packRef = db.collection(`users/${user.uid}/packs`).doc(selectedPackId);
      const cardsRef = packRef.collection('cards');
  
      // Delete all documents in the "cards" collection
      const cardsSnapshot = await cardsRef.get();
      cardsSnapshot.forEach((cardDoc) => {
        batch.delete(cardDoc.ref);
      });
  
      // Delete the pack document
      batch.delete(packRef);
  
      // Commit the batch write operation
      await batch.commit();
  
      console.log('Pack and its cards successfully deleted!');
      history.push('/studio');
  
      // Remove the deleted pack from userPacks
      setUserPacks(userPacks.filter((pack) => pack.id !== selectedPackId));
    } catch (error) {
      console.error('Error removing pack and its cards:', error);
    }
    
    setOpen(false);
  };
  
  
    // State to handle modal open/close
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    // Function to open modal
    const handleOpenModal = (pack) => {
      setIsModalOpen(true);
      setEditPackSettings(true);
      setSelectedPackId(pack.id)
      console.log('selectedPackId: ', pack.id)
      setSelectedImage(pack.coverImage)
      console.log('selectedImage: ', pack.coverImage)
      setTargetLanguage(pack.targetLanguage)
      console.log('targetLanguage: ', pack.targetLanguage)
      setTargetVoice(pack.targetVoice)
      console.log('targetVoice: ', pack.targetVoice)
      setSpeechRate(pack.speechRate)
      console.log('speechRate: ', pack.speechRate)
    };
  
    // Function to close modal
    const handleCloseModal = () => {
      setIsModalOpen(false);
      setEditPackSettings(false);
    };

  
  return (
    <div sx={{display: 'flex'}}>
      <Drawer
        variant="persistent"
        anchor="left"
        open={props.isOpen}
        sx={{
          width: drawerWidth,
          '& .MuiDrawer-paper': {
            marginTop: '64px',
            paddingBottom: '30px!important',
            width: drawerWidth,
            overflowY: 'auto',
            boxShadow: '6px 6px 20px rgba(0, 0, 0, 0.25)!important',
          },
        }}
      >
        <div 
          style={{
            position: 'fixed',
            zIndex: 1001,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0 16px',
            background: 'var(--clr-primary1)',
            width: '400px',
            height: '64px',
            marginRight: '-2px',
            color: 'var(--clr-text-light)',
          }}
        >
            <Typography variant="h6" noWrap>
              My Packs
            </Typography>
          
            <IconButton onClick={() => props.setIsOpen(false)}>
              <ClearIcon sx={{color: 'white'}} />
            </IconButton>
        
          </div>

          <List className="my-packs-list">
            {userPacks.length === 0 &&
            <div className="drawer-help-text">
              <p className="helpTextBody">Welcome to the Studio.<br/>
                Click "new pack" below to make your first pack.</p>
              <div 
              onClick={() => props.setIsOpen(false)}
              variant="contained"
              style={{marginTop: '50px', background: 'var(--clr-primary1)', color: 'var(--clr-text-light)', padding: '10px', borderRadius: '5px', cursor: 'pointer'}}
              >
                 <CreatePackModal style={{border: '1px solid yellow'}}/>
              </div>
            </div>}

          {userPacks && userPacks
          .reverse()
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map((pack) => (
              <React.Fragment   key={pack.id}>
                    
              <ListItem className="pack" pack={pack}>
              
                <ListItemAvatar
                className="avatarContainer"
                onClick={() => {handlePackClick(pack)
                }}>
                  <img
                    src={pack.coverImage}
                    alt=" "
                    className="pack-thumb"
                  />
                </ListItemAvatar>  

                <ListItemText 
                  primary={pack.title} 
                  secondary={`Total cards:  ${pack.numbCards}`}
                  onClick={() => {handlePackClick(pack)}}
                  className="list-item-text"
                />

                <IconButton 
                className="pack-settings-button"
                onClick={() => {handleOpenModal(pack)}}
                >
                  <SettingsIcon 
                  style={{
                    fontSize: '1.5rem',
                    color: 'var(--clr-primary1)',
                    marginRight: '6px',
                    marginTop: '2px',
                    border: '0px solid blue',
                  }}
                  />
                </IconButton>
              
                <IconButton 
                className="delete-pack-button"
                onClick={() => {
                    setSelectedPackId(pack.id);
                    setOpen(true)
                }}
                >
                  <DeleteIcon 
                  style={{
                    fontSize: '1.5rem',
                    color: 'var(--clr-primary1)',
                    marginRight: '6px',
                    border: '0px solid blue',
                  }}
                  />
                </IconButton>

              </ListItem>

              <Divider variant="inset" component="li" />
            </React.Fragment>
                ))}

          </List>
        
      </Drawer>

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
      >
          <div style={{
            marginTop: '100px',
            marginLeft: 'calc(50% - 325px)',
            width: '650px',
            borderRadius: '20px',
            backgroundColor: 'var(--clr-text-light)',
          }}>
            <CreatePack 
              editPackSettings={editPackSettings}
              onClose={handleCloseModal}
              packId={selectedPackId}
              imgPath={selectedImage}
              targetLanguage={targetLanguage}
              targetVoice={targetVoice}
              speechRate={speechRate}
              style={{
                background: 'var(--clr-text-light)',
                borderRadius: '20px',
              }}/>
          </div>
    
      </Modal>

      <WarningDialog 
          open={open} 
          onClose={() => setOpen(false)} 
          title={`Delete Pack: ${selectedPackId}`}
          message="Are you sure you want to delete this pack?" 
          primaryButton={{ label: "Delete", onClick: () => {handleDeletePack(selectedPackId)}}} 
          secondaryButton={{ label: "Cancel", onClick: () => setOpen(false) }} 
        />
    </div>
  
  );
}
