import { useEffect, useState, useRef } from "react"
import { db } from "../firebase/config"
import { useAuthContext } from './useAuthContext'

export const useCollection = (collection, _query, _orderBy) => {
  const [documents, setDocuments] = useState(null)
  const [error, setError] = useState(null)
  const { user } = useAuthContext()
  const uid = user ? user.uid : null
  // if we don't use a ref --> infinite loop in useEffect
  // _query is an array and is "different" on every function call
  const query = useRef(_query).current
  const orderBy = useRef(_orderBy).current//takes 2 values("prop", "asc"  or "desc")

  useEffect(() => {
    let ref = db.collection(collection)

    if (query) {
      ref = ref.where(...query)
    }
    if (orderBy) {
      ref = ref.orderBy(...orderBy)
    }

    const unsubscribe = ref.onSnapshot(snapshot => {
      let results = []
      snapshot.docs.forEach(doc => {
        results.push({...doc.data(), id: doc.id})
      });

      //save to local storage
      if (collection === 'full-packs') localStorage.setItem('fullpacks', JSON.stringify(results))
      if (collection === 'snack-packs') localStorage.setItem('snackpacks', JSON.stringify(results))
      if (collection === `users/${uid}/packs/`) localStorage.setItem('userpacks', JSON.stringify(results))
      if (collection === 'tutorials') localStorage.setItem('tutorials', JSON.stringify(results))
      
      // update state
      setDocuments(results)
      setError(null)
    }, error => {
      console.log(error)
      setError('could not fetch the data')
    })

    // unsubscribe on unmount
    return () => unsubscribe()

  }, [uid, collection, query, orderBy])

  return { documents, error }
}