import { NavLink } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import { useLogout } from '../hooks/useLogout'

// styles
import styles from './Navbar.css'

export default function Navbar() {
  const { logout } = useLogout()
  const { user } = useAuthContext()

  return (
    <>
    <nav className="navbar">
      <ul>
        {/* <li className="navbar-title"
        style={{
          paddingTop: '10px',
          marginLeft: '30px',
          marginRight: 'auto',
          opacity: .8,
        }}>LingoWise</li> */}
        
        

        {user && (
          <>
            <ul className="navbar-links">
              <li>
                <NavLink exact to="/dashboard">Dashboard</NavLink>
                </li>
                <li>
                  <NavLink exact to="/gallery">Gallery</NavLink>
              </li>
              <li>
                <NavLink exact to="/studio">Studio</NavLink>
              </li>
            </ul>
            
            <li style={{
              paddingTop: '4px', 
              whiteSpace: 'nowrap',
              minWidth: '150px'
              }}>
              Hello, {user.displayName}</li>
            <li>
              <button className="btn" onClick={logout}
              style={{
                marginTop: '4px',
                marginRight: '20px',
                whiteSpace: 'nowrap',
              }}>Sign Out</button>
            </li>
          </>
        )}
      </ul>
  
    </nav>
      
    </>
  )
}
