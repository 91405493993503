import * as React from 'react'
import { useState, useEffect } from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Divider from '@mui/material/Divider';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import { useHistory } from 'react-router-dom';
import VideoModal from '../../components/dialogs/VideoModal';

// search styles
import './SearchBar.css';


export default function TutorialsList() {
  const [tutorials, setTutorials] = useState([])
  const [error, setError] = useState(null)
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  //video mobile state
  const [isOpen, setIsOpen] = useState(false);
  const [video, setVideo] = useState(null);
  const [title, setTitle] = useState('');
  const [runtime, setRuntime] = useState('');
  const [description, setDescription] = useState('');
  const [src, setSrc] = useState('');

  const hasSearchValue = Boolean(searchValue);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  useEffect(() => {
    const storedPacks = localStorage.getItem('tutorials')
    setTutorials(JSON.parse(storedPacks) || []) // Set to empty array if no data in localStorage

  }, [])

  const handleItemClick = (videoData) => {
    setVideo(videoData.video);
    setTitle(videoData.title);
    setRuntime(videoData.runtime);
    setDescription(videoData.description);
    setSrc(videoData.src);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  }

  return (
    <>
    <div className="listNav">
      <p className="listNavTitle">Tutorials</p>
      {/* <SearchBar /> */}
      <TextField
      className="searchBar"
      value={searchValue}
      onChange={handleSearchChange}
      placeholder="Search"
      variant="outlined"
      size="small"
      fullWidth
      InputProps={{
        endAdornment: hasSearchValue ? (
          <ClearIcon sx={{ ml: 1, cursor: 'pointer' }} onClick={handleClearSearch} />
        ) : (
          <SearchIcon sx={{ ml: 1, mr: -1 }} />
        ),
        sx: {
          position: 'absolute',
          marginTop: '10px',
          right: '40px',
          width: '40%',
          borderRadius: '4px',
          backgroundColor: 'white',
          boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.3)',
          '& .MuiInputBase-input': {
            py: '10px',
            px: '10px',
            transition: 'none',
          },
          '&:hover': {
            boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
          },
          '&.Mui-focused': {
            boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
          },
        },
      }}
    />
    </div>
      <List className="packs-list">
        {tutorials.filter(pack => pack.title.toLowerCase().includes(searchValue.toLowerCase())).sort((a, b) => a.LWid - b.LWid).map((pack) => {
          if(pack.isActive){
          return (
            <React.Fragment key={pack.LWid}>
              <ListItem className="pack" onClick={() => handleItemClick(pack)}>
                <ListItemAvatar>
                  <img
                    src={pack.coverImage}
                    alt=" "
                    className="pack-thumb"
                  />
                </ListItemAvatar>  

                <ListItemText 
                  primary={pack.title} 
                  secondary={`Runtime: ${pack.runtime}`}
                  style={{color: 'var(--clr-text-dark)'}}
                />
          
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          )
          } else {
            return null
          }
        })}
      </List>
      <VideoModal 
        isOpen={isOpen}
        onClose={handleCloseModal}
        video={video}
        title={title}
        runtime={runtime}
        description={description}
        src={src}
      />
  
    </>
  )
}
