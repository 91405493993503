import { useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import { useAuthContext } from '../hooks/useAuthContext'
import DeleteIcon from '@mui/icons-material/Delete'
import { formatDistanceToNow } from 'date-fns';
import WarningDialog from './dialogs/WarningDialog'

// styles
import styles from './ScoresList.css'
import { Percent } from '@mui/icons-material';

export default function ScoresList({ scores }) {
  const { user } = useAuthContext()
  const [open, setOpen] = useState(false)
  const [selectedScoreId, setSelectedScoreId] = useState(null)

  const handleDelete = async () => {
    try {
      await firebase.firestore().collection(`users/${user.uid}/scores/`).doc(selectedScoreId).delete()
      console.log('Document successfully deleted!')
    } catch (error) {
      console.error('Error removing document: ', error)
    }
    setOpen(false)
  }

  return (
    <>
        <ul className="scores-list">
          {scores.map((data) => {
            // Convert Firebase timestamp to Date object
            const date = data.createdAt.toDate();

            // Format date as a relative time value
            const formattedDate = formatDistanceToNow(date, { addSuffix: true });

            const formattedScore = Math.round(data.score).toFixed(0)

            return (
              <li key={data.id} className="score-content">
                <span className="score-txt">{data.pack}</span>
                <span className="score-txt">{formattedScore}%</span>
                <span className="score-txt" style={{textAlign: 'right'}}>{formattedDate}</span>
                <div className='score-txt' style={{background: 'none', cursor: 'pointer'}}
                 onClick={() => {
                   setSelectedScoreId(data.id)
                   setOpen(true)
                 }}>
                  <DeleteIcon style={{
                    position: 'relative',
                    top: '5px',
                    color: 'var(--clr-primary1)',
                    fontSize: '2rem',
                    lineHeight: '1em',
                    padding: '0px',
                    marginLeft: '50px',
                  }}/>
                </div>
              </li>
            );
          })}
        </ul>
        <WarningDialog 
          open={open} 
          onClose={() => setOpen(false)} 
          title="Delete Score" 
          message="Are you sure you want to delete this score?" 
          primaryButton={{ label: "Delete", onClick: handleDelete }} 
          secondaryButton={{ label: "Cancel", onClick: () => setOpen(false) }} 
        />
    </>
  )
}
