import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide }  from 'swiper/react'
import SelectLang from '../../components/dialogs/SelectLang'
// styles
import './Gallery.css'
import 'swiper/css'

export default function SnackPacksCarousel({ snackpacks }) {
  const [currentPackTitle, setCurrentPackTitle] = useState("")
  const [open, setOpen] = useState(false)
  const [packType, setPackType] = useState("snack-packs")
  const [selectedPack, setSelectedPack] = useState("")
  const history = useHistory()

  const handleClick = (pack) => {
    if(pack.isLangPack){
      setSelectedPack(pack.id);
      setCurrentPackTitle(pack.title);
      setOpen(true);//open dialog to select language
      setPackType('snack-packs');
    } else if(!pack.isLangPack){
      setPackType('snack-packs');
      setSelectedPack(pack.id);
      setCurrentPackTitle(pack.title);
      history.push(`${packType}/${pack.id}/cards`);
    }
    localStorage.setItem('currentPack', pack.title);
  }

  return (
    <>
    <Swiper
      spaceBetween={20}
      slidesPerView={6}
      slidesOffsetAfter={100}
      slidesOffsetBefore={50}
    >
      {snackpacks.sort((a, b) => a.LWid - b.LWid).map(pack => {
        if (pack.isActive) {
          return (
            <SwiperSlide  
            key={pack.id} 
            onClick={()=>handleClick(pack)}
            style={{
              width: '200px',
              minWidth: '170px',
              maxWidth: '250px',
              border: '0px dotted blue',
            }}>
                  <div  className="snack-card">
                    <div className="snack-icon-holder">
                    <img src={pack.coverImage} alt=" " className="snack-icon" 
                    style={{color: '#fff' }}/></div>
                    <span className="snack-title">{pack.title}</span>
                  </div>
            </SwiperSlide>
          );
        } else {
          return null;
        }
      })}
    </Swiper>
    <SelectLang packType={packType} selectedPack={selectedPack} open={open} setOpen={setOpen} />
    </>
  );
}