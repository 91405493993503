import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'

//import styles
import './Gallery.css'

// components
import FullPacksCarousel from './FullPacksCarousel'
import SnackPacksCarousel from './SnackPacksCarousel'
import UserPacksCarousel from './UserPacksCarousel'
import TutorialsCarousel from './TutorialsCarousel'

export default function Gallery() {
  const { user } = useAuthContext()
  //store user native lang for later use
  const sourceLang = (navigator.language).substr(0, 2)
  localStorage.setItem('sourceLang', sourceLang)
  //const targetLang = localStorage.getItem('targetLang')

  const { documents: fullpacks, ferror } = useCollection('full-packs')
  const { documents: snackpacks, sError } = useCollection('snack-packs')
  const { documents: userpacks, uerror } = useCollection(
    `users/${user.uid}/packs/`, null, ["createdAt", "desc"]
  ) || []

  
  const { documents: tutorials, tError } = useCollection('tutorials')

  return (
    <div className="gallery-container">
    <section className="full-packs">
    <div className="section-header">
      <h1 className="gallery-title">Full Packs</h1>
      <Link to="/fullpackslist"><span className="view-all">VIEW ALL</span></Link>
    </div>
        {ferror && <p>{ferror}</p>}
        {fullpacks && <FullPacksCarousel fullpacks={fullpacks} />}
     </section>

     <section className="snack-packs">
     <div className="section-header">
        <h1 className="gallery-title">Snack Packs</h1>
        <Link to="/snackpackslist"><span className="view-all">VIEW ALL</span></Link>
      </div>
        {sError && <p>{sError}</p>}
        {snackpacks && <SnackPacksCarousel snackpacks={snackpacks} />}
     </section>

    <section className="user-packs">
      { userpacks && userpacks.length > 0 && ( 
      <div className="section-header">
        <h1 className="gallery-title">My Packs</h1>
        <Link to="/userpackslist"><span className="view-all">VIEW ALL</span></Link>
      </div> 
      )}
        {uerror && <p>{uerror}</p>}
        { userpacks && <UserPacksCarousel userpacks={userpacks} />}
     </section>

     <section className="tutorials">
      <div className="section-header">
        <h1 className="gallery-title">Tutorials</h1>
        <Link to="/tutorialslist"><span className="view-all">VIEW ALL</span></Link>
      </div>
        {tError && <p>{tError}</p>}
        {tutorials && <TutorialsCarousel tutorials={tutorials} />}
     </section>
      
    </div>
  )
}
