import { useState } from "react";
import CreatePackModal from "../../components/studio/CreatePackModal";
import { useParams, useHistory } from "react-router-dom";

//components
import UserCardsList from './UserCardsList'
import CreateCardForm from './CreateCardForm'

//styles 
import "./Studio.css";

import SideDrawer from "./SideDrawer";
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

export default function Studio ({ uid }) {
  const packId = useParams().id;
  const [isOpen, setIsOpen] = useState(true)
  const [selectedPackId, setSelectedPackId] = useState("");
  const [targetLanguage, setTargetLanguage] = useState("");
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const hasSearchQuery = Boolean(searchQuery);
  const [numbCards, setNumbCards] = useState(0);
  const history = useHistory();

  const handleNumbCardsUpdate = (numbCards) => {
    setNumbCards(numbCards);
  };

  const handleToggleDrawer = (isOpen) => {
    setIsOpen(isOpen);
    console.log('drawerOpen', isOpen)
  };

  const handlePackSelect = (pack) => {
    setSelectedPackId(pack.title);
    setTargetLanguage(pack.targetLanguage);
    localStorage.setItem('targetLanguage', targetLanguage);
    setIsOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  return (

    <div className="studio-container">

        <div className="studio-nav">

            <div className="studio-nav-start">
                <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleToggleDrawer}
                sx={{ mr: 2,
                  marginLeft: '20px',
                  position: 'absolute',
                  top: '4px',
                  left: '0px',
                }}
              >
                <MenuIcon />
              </IconButton>
              
              {packId ? <span
              style={{
                width: 'fit-content',
                whiteSpace: 'nowrap',
              }}
              >{`${packId} : ${numbCards} ${numbCards === 1 ? 'card' : 'cards'}`
            }</span> 
              : <span style={{
                width: 'fit-content',
                whiteSpace: 'nowrap',
              }}
              >Please Select a Pack</span>}  

              <SideDrawer 
              isOpen={isOpen} 
              setIsOpen={setIsOpen} 
              selectedPackId={selectedPackId}
              handlePackSelect={handlePackSelect}
              onNumbCardsUpdate={handleNumbCardsUpdate}
              />
            </div>

            <div onClick={()=>setIsOpen(false)} className="studio-nav-middle">
              <CreatePackModal />
            </div>

            <div className="studio-nav-end">
            <TextField
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search"
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    endAdornment: hasSearchQuery ? (
                      <ClearIcon sx={{ ml: 1, cursor: 'pointer' }} onClick={handleClearSearch} />
                    ) : (
                      <SearchIcon sx={{ ml: 1, mr: -1 }} />
                    ),
                    sx: {
                      width: '200px',
                      backgroundColor: 'white',
                      boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.3)',
                      '& .MuiInputBase-input': {
                        py: '10px',
                        px: '10px',
                        transition: 'none',
                      },
                      '&:hover': {
                        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                      },
                      '&.Mui-focused': {
                        boxShadow: 'inset 0 0 8px rgba(0, 0, 0, 0.3)',
                      },
                    },
                  }}
                />
            </div>
        </div>
        {/* <div style={{margin: '150px', rightMargin: '40px'}}>
              <Button
                  variant="outlined"
                  color="primary"
                  fontSize="1rem!important"
                  className="practice-button"
                  onClick={() => history.push(`/user-packs/${packId}`)}
              >Practice this Pack
              </Button>
        </div> */}
       <div className="studio-content">
          {error && <p>{error}</p>}
          {packId && <div className="create-cards-grid">
              <div className="left-column">
                <UserCardsList 
                  packId={packId} 
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </div>
              <div className="right-column">
                <CreateCardForm packId={packId} className="make-cards-form" />
              </div>
          </div>}
  
        </div> 
    </div>  

  )
}


