//styles
import './ImageCard.css'
import { useState, useEffect } from 'react'

//styles & icons & audio
import './ImageCard.css'
import {ReactComponent as ThumbsdownIcon} from "../../assets/icons/thumbs-down-solid.svg";
import {ReactComponent as ThumbsupIcon} from "../../assets/icons/thumbs-up-solid.svg";


export default function ImageCard({
  id, 
  doc,
  onHandleAnswer,
  onLingoAudio,
  }) {

  const [collection, setCollection] = useState('');
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    // check doc.collection and set the collection state based on that value
    if (doc.collection === 'world_capitols') {
      setCollection('world_capitols');
    } else if (doc.collection === 'world_capitols_americas') {
      setCollection('world_capitols_americas');
    } else if (doc.collection === 'world_capitols_europe') {
      setCollection('world_capitols_europe');
    } else if (doc.collection === 'world_capitols_africa') {
      setCollection('world_capitols_africa');
    } else if (doc.collection === 'world_capitols_asia') {
      setCollection('world_capitols_asia');
    } else if (doc.collection === 'world_capitols_oceania') {
      setCollection('world_capitols_oceania');
    } else if (doc.collection === 'world_capitols_middle_east') {
      setCollection('world_capitols_middle_east');
    } else if (doc.collection === 'us_capitols') {
      setCollection('us_capitols');
    }
  }, [doc]);
    
    const handleClick = async () => {
      setIsFlipped(!isFlipped);
      if (!isFlipped) {
        onLingoAudio(doc.audioFile);
      }
    };
  
    // render the component based on the collection state
  if (collection === 'world_capitols' || collection === 'world_capitols_americas' || collection === 'world_capitols_europe' || collection === 'world_capitols_africa' || collection === 'world_capitols_asia' || collection === 'world_capitols_oceania' || collection === 'world_capitols_middle_east') {
    return (
      <div className="card-container" onClick={handleClick}>
      <div className={`card ${isFlipped ? "flipped" : ""}`}>
        <div className="front">
          <img src={`../../assets/flags/world_capitols/${doc.imageFile}`} className="medium-img" alt="" />
          <span className="imageCard-frontText">{doc.front}</span>
        </div>
        <div className="back">
          <img src={`../../assets/flags/world_capitols/${doc.imageFile}`} className="small-img" alt="" />
          <span className="imageCard-backText">{doc.back}</span>
          <div className="buttons" style={{
              position: 'absolute',
              bottom: '90px',
              width: '100%',
            }}>

            <ThumbsdownIcon
              onClick={() => onHandleAnswer(false)}
              style={{
                fill: 'var(--clr-secondary1)',
                width: '3rem',
                marginLeft: '20px',
               marginTop: '10px',
              }} />

            <ThumbsupIcon
              onClick={() => onHandleAnswer(true)}
              style={{
                fill: 'var(--clr-success)',
                width: '3rem',
                marginRight: '20px',
              }} />
          </div>
        </div>
      </div>
    </div>
    );
  } else if (collection === 'us_capitols') {
    return (
      <div className="card-container" onClick={handleClick}>
        <div className={`card ${isFlipped ? "flipped" : ""}`}>
          <div className="front">
            <img src={`../../assets/flags/us_capitols/${doc.imageFile}`} className="medium-img" alt="" />
            <span className="imageCard-frontText">{doc.front}</span>
          </div>
          <div className="back">
            <img src={`../../assets/flags/us_capitols/${doc.imageFile}`} className="small-img" alt="" />
            
            <span className="imageCard-backText">{doc.back}</span>

            <div className="buttons" style={{
                position: 'absolute',
                bottom: '90px',
                width: '100%',
              }}>

                <ThumbsdownIcon
                  onClick={()=> onHandleAnswer(false)}
                  style={{
                    fill: 'var(--clr-secondary1)',
                    width: '3rem',
                    marginLeft: '20px',
                    marginTop: '10px',
                  }} />

                <ThumbsupIcon
                  onClick={()=> onHandleAnswer(true)}
                  style={{
                    fill: 'var(--clr-success)',
                    width: '3rem',
                    marginRight: '20px',
                  }} />
              </div>

          </div>
        </div>
      </div>
    );
  } else {
    return null; // return null if the collection value is not recognized
  }
};
