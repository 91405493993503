import React, { useState } from "react";

import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

import { makeStyles } from '@mui/styles';
const useStylesQuizDialog = makeStyles((theme) => ({
  dialog: {
    maxWidth: 400,
    minHeight: 250,
    borderRadius: '10%',
    marginTop: '-20px'
  },
  paper: {
      borderRadius: 5,
  },
}));
function QuizDialog({ 
  open, 
  onClose, 
  onOk, 
  quizTitle, 
  quizSubTitle, 
  quizOptions,
  totalCards,
  primaryButtonLabel,
  secondaryButtonLabel,
}) {



  const classes = useStylesQuizDialog();
  const [selectedValue, setSelectedValue] = useState("10");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleOk = () => {
    onOk(selectedValue);
  };
  const handleCancel = () => {
    {onClose()}
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      aria-labelledby="quiz-dialog-title"
      classes={{ paper: classes.dialog }} 
      PaperProps={{
        classes: { root: classes.paper },
        elevation: 10, // <-- remove box shadow
        // <-- add border radius
      }}>
      <DialogTitle 
      style={{
        padding: 0,
        margin: '10px 20px 0px 20px',
        fontSize: '1.5rem',
        lineHeight: '1em',
        maxHeight: '1.5em',
        color: 'var(--clr-primary1)',
        border: '0px solid red'
      }}>
        <p className="quizDialogTitle">{quizTitle}</p>
        </DialogTitle>
      <DialogContent>
      <FormControl component="fieldset">
    <FormLabel component="legend"
    style={{
      fontSize: '1.15rem',
      lineHeight:' 1.5em',
      marginBottom: '6px',
      color: 'var(--clr-text-dark)',
    }} >{quizSubTitle}</FormLabel>

    <RadioGroup 
    aria-label="quiz dialog" 
    name="quiz" 
    value={selectedValue} 
    onChange={handleChange}>
      {quizOptions.map(option => (
        <FormControlLabel
          key={option.value}
          value={option.value.toString()}
          control={<Radio />}
          label={option.label}
        />
      ))}
    </RadioGroup>
  </FormControl>
      </DialogContent>
      <DialogActions style = {{marginBottom: '20px', marginRight: '20px'}}>
        {totalCards >= 10 && <Button onClick={handleCancel} variant="outlined" color="secondary">
          {secondaryButtonLabel}
        </Button>}
        {totalCards >= 10 && <Button onClick={handleOk} 
        variant="contained" 
        color="primary"
        style={{'&:hover': {background: 'var(--clr-tertiary1)!important'} }}>
          {primaryButtonLabel}
        </Button>}
        {totalCards < 10 && <Button onClick={handleCancel} 
        variant="outlined" 
        color="secondary"
        style={{'&:hover': {background: 'var(--clr-tertiary1)!important'} }}>
          {secondaryButtonLabel}
        </Button>}
      </DialogActions>
    </Dialog>
  );
}

export default QuizDialog;





