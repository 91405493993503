import React, { useState } from "react";
import CreatePack from "./CreatePack";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
//styles
import "./CreatePackModal.css";

const CreatePackModal = ({ editPackSettings, handleDrawerClose }) => {
  const [open, setOpen] = useState(false);


  const handleOpen = () => {
    setOpen(true);
  }
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button 
        variant="text" 
        onClick={handleOpen}
        style={{
          whiteSpace: 'nowrap',
          color: "white",
          fontWeight: "bold",  
          textAlign: "center",
          width: 'fit-content',
          maxHeight: '50px!important',
          border: '0px solid white',
        }}
        startIcon = {<AddIcon style={{
          fontSize: "1.25em", 
          paddingBottom: '2px',
          marginLeft: '10px',
         }} 
          />}
      >
        New Pack
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="modal-container"
          style={{
            width: '100%',
            height: "fit-content",
            borderRadius: "15px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
            animation: `${open ? "fadeIn" : "fadeOut"} 0.5s`,
          }}
        >
          <CreatePack 
            editPackSettings={editPackSettings} 
            handleClose={handleClose} 
          />
        </div>
      </Modal>
    </>
  );
};

export default CreatePackModal;
