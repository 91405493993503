import { useState } from 'react'
import { useLogin } from '../../hooks/useLogin'
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// styles
import './Login.css'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const { login, error, isPending } = useLogin()

  const handleSubmit = (e) => {
    e.preventDefault()
    login(email, password)
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="login-container">
      <header className="login-header">
        <h1 className="login-title">Welcome to LingoWise</h1>
        <h4 className="login-sub-title">private beta v 1.0.1</h4>
      </header>
      <div className="login-wrapper">
        <div className="login-logo">
          <img src="../../assets/intro-o.svg" alt="Owlivia"
            style={{
              // width: '300px', 
              // height: 'auto',
              // marginLeft: 'calc(50% - 150px)',
              border: '0px solid black',
              }}/>
        </div>

          <form onSubmit={handleSubmit} className="login-form">
            <h3 className="section-title">please sign in</h3>
            <label>
              <span>email:</span>
              <TextField
                type="email" 
                onChange={(e) => setEmail(e.target.value)} 
                value={email}
                className="loginInput"
              />
            </label>
            <label>
              <span>password:</span>
              <TextField 
                type={showPassword ? 'text' : 'password'} 
                onChange={(e) => setPassword(e.target.value)} 
                value={password}
                className="loginInput"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                
                }}
              />
            </label>
            { !isPending && <button className="btn-login">Sign In</button> }
            { isPending && <button className="btn-login" disabled>loading</button> }
            { error && <p className="errorText">{error}</p> }
          </form>
      </div>
    </div>
  )
}
