import { useEffect, useRef } from 'react';
import './VideoModal.css'

const VideoModal = ({ 
    isOpen, 
    onClose, 
    video, 
    title, 
    runtime, 
    description, 
    src
}) => {

    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
    
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [modalRef, onClose]);
    
      if (!isOpen) {
        return null;
      }

  return (
    <div className="modal" onClick={onClose} >
      <div className="modal-content"onClick={(event) => event.stopPropagation()}>
        <div className="video-container">
          <video src='../../assets/video/make_your_own_cards.mp4' autoPlay controls style={{
            width: '100%',
            height: '80%',
            border: '0px solid yellow',
          }} />
        </div>
        <div className="video-info">
          <h3 className="video-title">{title}</h3>
          <p className="video-runtime">Runtime: {runtime}</p>
          <p className="video-description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
