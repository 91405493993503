import { useAuthContext } from '../../hooks/useAuthContext'
import { useCollection } from '../../hooks/useCollection'

import ScoresList from '../../components/ScoresList'
//styles
import './Dashboard.css'

export default function Dashboard() {
    const { user } = useAuthContext()
    const { documents, error } = useCollection(
     `users/${user.uid}/scores/`, null, ['createdAt', 'desc'])
    
    console.log(documents)
  return (
    <div className="dashboard-container">
        {documents && documents.length > 0 && (
         <section className="dashboard-scores">
            <h4>Quiz Scores</h4>
            <div className="scoresList">
            {error && <p>{error}</p>}
            {documents && <ScoresList scores={documents} />}
            </div>
        </section>
        )}
        {!documents || documents.length <= 0 && (
         <section className="dashboard-scores">
            <h4>Quiz Scores</h4>
            <div className="scoresList">
                <p className="dash-txt">No scores to display yet.</p>
            </div>
        </section>
        )}
{/* 
        <section className="dashboard-settings">
            <h4>Settings</h4>
            <div className="scoresList">
                <p className="dash-txt">User preferences go here</p>
            </div>
        </section>

        <section className="dashboard-account">
            <h4>Account</h4>
            <div className="scoresList">
                <p className="dash-txt">Account stuff goes here</p>
                <p className="dash-txt">Receipts etc</p>
            </div>
        </section> */}
        
    </div>
  )
}
