//styles
import './Footer.css'

export default function Footer() {
  return (
    <div className="footer-container">
      &nbsp;
    </div>
  )
}
