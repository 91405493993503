import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import { createTheme, ThemeProvider } from '@mui/material'
import { Helmet } from 'react-helmet'
// pages & components
import Login from './pages/login/Login'
import Signup from './pages/signup/Signup'
import Navbar from './components/Navbar'
import Gallery from './pages/gallery/Gallery'
import FullPacksList from './pages/gallery/FullPacksList'
import SnackPacksList from './pages/gallery/SnackPacksList'
import UserPacksList from './pages/gallery/UserPacksList'
import TutorialsList from './pages/gallery/TutorialsList'
import Deck from './pages/deck/Deck'
import Dashboard from './pages/dashboard/Dashboard'
import Studio from './pages/studio/Studio'
import Footer from './components/Footer'

//color 
import { deepPurple, pink } from '@mui/material/colors'

function App() {
  const { authIsReady, user } = useAuthContext()

  const theme = createTheme({
    palette: {
      primary: deepPurple,
      secondary: pink
    }
  })

  return (
    <ThemeProvider theme={theme}>
    <div className="App">
      <Helmet>
        <title>LingoWise</title>
      </Helmet>
    
      {authIsReady && (
        <BrowserRouter baselink="betatest.lingowise.app/gallery">
          <Navbar />
          <Switch>
          <Route exact path="/">
              {!user && <Redirect to="/login" />}
              {user && <Gallery />}
          </Route>
          <Route path="/studio/:id">
              {!user && <Redirect to="/login" />}
              {user && <Studio />}
          </Route>
          <Route path="/:packType/:id">
              {!user && <Redirect to="/login" />}
              {user && <Deck />}
          </Route>
          <Route path="/dashboard">
              {!user && <Redirect to="/login" />}
              {user && <Dashboard />}
          </Route>
          <Route path="/gallery">
              {!user && <Redirect to="/login" />}
              {user && <Gallery />}
          </Route>
          <Route path="/fullpackslist">
              {!user && <Redirect to="/login" />}
              {user && <FullPacksList />}
          </Route>
          <Route path="/snackpackslist">
              {!user && <Redirect to="/login" />}
              {user && <SnackPacksList />}
          </Route>
          <Route path="/userpackslist">
              {!user && <Redirect to="/login" />}
              {user && <UserPacksList />}
          </Route>
          <Route path="/tutorialslist">
              {!user && <Redirect to="/login" />}
              {user && <TutorialsList />}
          </Route>
          <Route path="/studio">
              {!user && <Redirect to="/login" />}
              {user && <Studio />}
          </Route>
          <Route path="/login">
              {user && <Redirect to="/gallery" />}
              {!user && <Login />}
          </Route>
          <Route path="/signup">
              {user && user.displayName && <Redirect to="/gallery" />}
              {!user && <Signup />}
          </Route>
          
          </Switch>
          <Footer />
        </BrowserRouter>
      
      )}
    </div>
    </ThemeProvider>
  );
}

export default App