const imageData = [
        {
          path: '../../../assets/images/alphabet.jpg',
          title: 'Alphabet',
          thumbnail: '../../../assets/thumbnails/alphabet.jpg',
        },
        // {
        //   path: '../../../assets/images/amsterdam1.jpeg',
        //   title: 'Amsterdam1',
        //   thumbnail: '../../../assets/thumbnails/amsterdam1.jpeg',
        // },
        {
          path: '../../../assets/images/baseball.jpg',
          title: 'Baseball',
          thumbnail: '../../../assets/thumbnails/baseball.jpg',
        },
        {
          path: '../../../assets/images/bigben.jpg',
          title: 'Big Ben',
          thumbnail: '../../../assets/thumbnails/bigben.jpg',
        },
        {
          path: '../../../assets/images/cab.jpg',
          title: 'Cab',
          thumbnail: '../../../assets/thumbnails/cab.jpg',
        },
        {
          path: '../../assets/images/car1.jpg',
          title: 'Car',
          thumbnail: '../../assets/thumbnails/car1.jpg',
        },
        {
          path: '../../assets/images/cat.jpg',
          title: 'Cat',
          thumbnail: '../../assets/thumbnails/cat.jpg',
        },
        {
          path: '../../assets/images/dalmation.jpg',
          title: 'Dalmation',
          thumbnail: '../../assets/thumbnails/dalmation.jpg',
        },
        {
          path: '../../assets/images/dining1.jpg',
          title: 'Dining1',
          thumbnail: '../../assets/thumbnails/dining1.jpg',
        },
        {
          path: '../../assets/images/dining2.jpg',
          title: 'Dining2',
          thumbnail: '../../assets/thumbnails/dining2.jpg',
        },
        {
          path: '../../assets/images/dining3.jpg',
          title: 'Dining3',
          thumbnail: '../../assets/thumbnails/dining3.jpg',
        },
        {
          path: '../../assets/images/double-decker.jpg',
          title: 'London Bus',
          thumbnail: '../../assets/thumbnails/double-decker.jpg',
        },
        {
          path: '../../assets/images/elephant.jpg',
          title: 'Elephant',
          thumbnail: '../../assets/thumbnails/elephant.jpg',
        },
        {
          path: '../../assets/images/england1.jpg',
          title: 'England',
          thumbnail: '../../assets/thumbnails/england1.jpg',
        },
        {
          path: '../../assets/images/england2.jpg',
          title: 'England',
          thumbnail: '../../assets/thumbnails/england2.jpg',
        },
        {
          path: '../../assets/images/england3.jpg',
          title: 'England',
          thumbnail: '../../assets/thumbnails/england3.jpg',
        },
        {
          path: '../../assets/images/football.jpg',
          title: 'football',
          thumbnail: '../../assets/thumbnails/football.jpg',
        },
        {
          path: '../../assets/images/france1.jpg',
          title: 'France',
          thumbnail: '../../assets/thumbnails/france1.jpg',
        },
        {
          path: '../../assets/images/france2.jpg',
          title: 'France',
          thumbnail: '../../assets/thumbnails/france2.jpg',
        },
        {
          path: '../../assets/images/france3.jpg',
          title: 'France',
          thumbnail: '../../assets/thumbnails/france3.jpg',
        },
        {
          path: '../../assets/images/germany1.jpg',
          title: 'Germany',
          thumbnail: '../../assets/thumbnails/germany1.jpg',
        },
        {
          path: '../../assets/images/germany2.jpg',
          title: 'Germany',
          thumbnail: '../../assets/thumbnails/germany2.jpg',
        },
        {
          path: '../../assets/images/germany3.jpg',
          title: 'Germany',
          thumbnail: '../../assets/thumbnails/germany3.jpg',
        },
        {
          path: '../../assets/images/giraffe.jpg',
          title: 'Giraffe',
          thumbnail: '../../assets/thumbnails/giraffe.jpg',
        },
        {
          path: '../../assets/images/golf.jpg',
          title: 'golf',
          thumbnail: '../../assets/thumbnails/golf.jpg',
        },
        {
          path: '../../assets/images/india.jpg',
          title: 'India',
          thumbnail: '../../assets/thumbnails/india.jpg',
        },
        {
          path: '../../assets/images/italy.jpg',
          title: 'Italy',
          thumbnail: '../../assets/thumbnails/italy.jpg',
        },
        {
          path: '../../assets/images/italy1.jpg',
          title: 'Italy',
          thumbnail: '../../assets/thumbnails/italy1.jpg',
        },
        {
          path: '../../assets/images/italy2.jpg',
          title: 'Italy',
          thumbnail: '../../assets/thumbnails/italy2.jpg',
        },
        {
          path: '../../assets/images/italy3.jpg',
          title: 'Italy',
          thumbnail: '../../assets/thumbnails/italy3.jpg',
        },
        {
          path: '../../assets/images/lion.jpg',
          title: 'lion',
          thumbnail: '../../assets/thumbnails/lion.jpg',
        },
        {
          path: '../../assets/images/math.jpg',
          title: 'math',
          thumbnail: '../../assets/thumbnails/math.jpg',
        },
        {
          path: '../../assets/images/meeting_others.jpg',
          title: 'meeting others',
          thumbnail: '../../assets/thumbnails/meeting_others.jpg',
        },
        // {
        //   path: '../../assets/images/menu.jpeg',
        //   title: 'Menu',
        //   thumbnail: '../../assets/thumbnails/menu.jpeg',
        // },
        {
          path: '../../assets/images/mini.jpg',
          title: 'mini',
          thumbnail: '../../assets/thumbnails/mini.jpg',
        },
        {
          path: '../../assets/images/monopoly.jpg',
          title: 'monopoly',
          thumbnail: '../../assets/thumbnails/monopoly.jpg',
        },
        {
          path: '../../assets/images/opera_house.jpg',
          title: 'opera house',
          thumbnail: '../../assets/thumbnails/opera_house.jpg',
        },
        {
          path: '../../assets/images/owl.jpg',
          title: 'owl',
          thumbnail: '../../assets/thumbnails/owl.jpg',
        },
        // {
        //   path: '../../assets/images/paris.jpg',
        //   title: 'Paris',
        //   thumbnail: '../../assets/thumbnails/paris.jpg',
        // },
        {
          path: '../../assets/images/paris1.jpg',
          title: 'Paris1',
          thumbnail: '../../assets/thumbnails/paris1.jpg',
        },
        {
          path: '../../assets/images/parrott.jpg',
          title: 'parrott',
          thumbnail: '../../assets/thumbnails/parrott.jpg',
        },
        {
          path: '../../assets/images/portugal.jpg',
          title: 'Portugal',
          thumbnail: '../../assets/thumbnails/portugal.jpg',
        },
        {
          path: '../../assets/images/portugal1.jpg',
          title: 'Portugal',
          thumbnail: '../../assets/thumbnails/portugal1.jpg',
        },
        {
          path: '../../assets/images/portugal2.jpg',
          title: 'Portugal',
          thumbnail: '../../assets/thumbnails/portugal2.jpg',
        },
        {
          path: '../../assets/images/portugal3.jpg',
          title: 'Portugal',
          thumbnail: '../../assets/thumbnails/portugal3.jpg',
        },
        {
          path: '../../assets/images/produce.jpg',
          title: 'produce',
          thumbnail: '../../assets/thumbnails/produce.jpg',
        },
        {
          path: '../../assets/images/rent-car.jpg',
          title: 'Renting Car',
          thumbnail: '../../assets/thumbnails/rent-car.jpg',
        },
        {
          path: '../../assets/images/reservations1.jpg',
          title: 'making reservations',
          thumbnail: '../../assets/thumbnails/reservations1.jpg',
        },
        // {
        //   path: '../../assets/images/rome1.jpeg',
        //   title: 'Rome',
        //   thumbnail: '../../assets/thumbnails/rome1.jpeg',
        // },
        // {
        //   path: '../../assets/images/santorini1.jpeg',
        //   title: 'Santorini',
        //   thumbnail: '../../assets/thumbnails/santorini1.jpeg',
        // },
        // {
        //   path: '../../assets/images/santorini2.jpeg',
        //   title: 'Santorini',
        //   thumbnail: '../../assets/thumbnails/santorini2.jpeg',
        // },
        // {
        //   path: '../../assets/images/santorini3.jpeg',
        //   title: 'Santorini',
        //   thumbnail: '../../assets/thumbnails/santorini3.jpeg',
        // },
        {
          path: '../../assets/images/science.jpg',
          title: 'science',
          thumbnail: '../../assets/thumbnails/science.jpg',
        },
        {
          path: '../../assets/images/scrabble.jpg',
          title: 'scrabble',
          thumbnail: '../../assets/thumbnails/scrabble.jpg',
        },
        {
          path: '../../assets/images/soccor.jpg',
          title: 'soccor',
          thumbnail: '../../assets/thumbnails/soccor.jpg',
        },
        {
          path: '../../assets/images/spain1.jpg',
          title: 'Spain',
          thumbnail: '../../assets/thumbnails/spain1.jpg',
        },
        {
          path: '../../assets/images/spain2.jpg',
          title: 'Spain',
          thumbnail: '../../assets/thumbnails/spain2.jpg',
        },
        {
          path: '../../assets/images/spain3.jpg',
          title: 'Spain',
          thumbnail: '../../assets/thumbnails/spain3.jpg',
        },
        // {
        //   path: '../../assets/images/takeoff.jpeg',
        //   title: 'takeoff',
        //   thumbnail: '../../assets/thumbnails/takeoff.jpeg',
        // },
        // {
        //   path: '../../assets/images/tennis.jpeg',
        //   title: 'tennis',
        //   thumbnail: '../../assets/thumbnails/tennis.jpeg',
        // },
        {
          path: '../../assets/images/transport1.jpg',
          title: 'cycling',
          thumbnail: '../../assets/thumbnails/transport1.jpg',
        },
        {
          path: '../../assets/images/transport2.jpg',
          title: 'London Bus',
          thumbnail: '../../assets/thumbnails/transport2.jpg',
        },
        {
          path: '../../assets/images/transport3.jpg',
          title: 'red motor scooter',
          thumbnail: '../../assets/thumbnails/transport3.jpg',
        },
        {
          path: '../../assets/images/turtle.jpg',
          title: 'turtle',
          thumbnail: '../../assets/thumbnails/turtle.jpg',
        },
        {
          path: '../../assets/images/valencia.jpg',
          title: 'Valencia',
          thumbnail: '../../assets/thumbnails/valencia.jpg',
        },
        {
          path: '../../assets/images/veg1.jpg',
          title: 'tobatoes, lettuce and other vege',
          thumbnail: '../../assets/thumbnails/veg1.jpg',
        },
        {
          path: '../../assets/images/veg2.jpg',
          title: 'fruit cups for sale',
          thumbnail: '../../assets/thumbnails/veg2.jpg',
        },
        {
          path: '../../assets/images/veg3.jpg',
          title: 'cucumbers',
          thumbnail: '../../assets/thumbnails/veg3.jpg',
        },
        {
          path: '../../assets/images/zebra.jpg',
          title: 'Zebras',
          thumbnail: '../../assets/thumbnails/zebra.jpg',
        },
        { // China
          country: 'China',
          path: '../../assets/flags/world_capitols/cn.svg',
          title: 'cn flag',
          thumbnail: '../../assets/flags/world_capitols/cn.svg',
        },
        { // czech republic
          country: 'Czech Republic',
          path: '../../assets/flags/world_capitols/cz.svg',
          title: 'cz flag',
          thumbnail: '../../assets/flags/world_capitols/cz.svg',
        },
        { // denmark
          country: 'Denmark',
          path: '../../assets/flags/world_capitols/dk.svg',
          title: 'dk flag',
          thumbnail: '../../assets/flags/world_capitols/dk.svg',
        },
        { // france
          country: 'France',
          path: '../../assets/flags/world_capitols/fr.svg',
          title: 'fr flag',
          thumbnail: '../../assets/flags/world_capitols/fr.svg',
        },
        { // Germany
          country: 'Germany',
          path: '../../assets/flags/world_capitols/de.svg',
          title: 'de flag',
          thumbnail: '../../assets/flags/world_capitols/de.svg',
        },
        { // gb
          country: 'Great Britain',
          path: '../../assets/flags/world_capitols/gb.svg',
          title: 'gb flag',
          thumbnail: '../../assets/flags/world_capitols/gb.svg',
        },
        { // Greece
          country: 'Greece',
          path: '../../assets/flags/world_capitols/gr.svg',
          title: 'gr flag',
          thumbnail: '../../assets/flags/world_capitols/gr.svg',
        },
        { // India
          country: 'India',
          path: '../../assets/flags/world_capitols/in.svg',
          title: 'in flag',
          thumbnail: '../../assets/flags/world_capitols/in.svg',
        },
        { // israel
          country: 'Israel',
          path: '../../assets/flags/world_capitols/il.svg',
          title: 'il flag',
          thumbnail: '../../assets/flags/world_capitols/il.svg',
        },
        { // italy
          country: 'Italy',
          path: '../../assets/flags/world_capitols/it.svg',
          title: 'it flag',
          thumbnail: '../../assets/flags/world_capitols/it.svg',
        },
        { // korea
          country: 'Korea',
          path: '../../assets/flags/world_capitols/kr.svg',
          title: 'kr flag',
          thumbnail: '../../assets/flags/world_capitols/kr.svg',
        },
        { // netherlands
          country: 'Netherlands',
          path: '../../assets/flags/world_capitols/nl.svg',
          title: 'nl flag',
          thumbnail: '../../assets/flags/world_capitols/nl.svg',
        },
        { // norway
          country: 'Norway',
          path: '../../assets/flags/world_capitols/no.svg',
          title: 'no flag',
          thumbnail: '../../assets/flags/world_capitols/no.svg',
        },
        { // poland
          country: 'Poland',
          path: '../../assets/flags/world_capitols/pl.svg',
          title: 'pl flag',
          thumbnail: '../../assets/flags/world_capitols/pl.svg',
        },
        { // portugal
          country: 'Portugal',
          path: '../../assets/flags/world_capitols/pt.svg',
          title: 'pt flag',
          thumbnail: '../../assets/flags/world_capitols/pt.svg',
        },
        { // russia
          country: 'Russia',
          path: '../../assets/flags/world_capitols/ru.svg',
          title: 'ru flag',
          thumbnail: '../../assets/flags/world_capitols/ru.svg',
        },
        { // spanish
          country: 'Spain',
          path: '../../assets/flags/world_capitols/es.svg',
          title: 'es flag',
          thumbnail: '../../assets/flags/world_capitols/es.svg',
        },
        { // sweden
          country: 'Sweden',
          path: '../../assets/flags/world_capitols/se.svg',
          title: 'se flag',
          thumbnail: '../../assets/flags/world_capitols/se.svg',
        },
        { // turkey
          country: 'Turkey',
          path: '../../assets/flags/world_capitols/tr.svg',
          title: 'tr flag',
          thumbnail: '../../assets/flags/world_capitols/tr.svg',
        },
        { // ukarine
          country: 'Ukraine',
          path: '../../assets/flags/world_capitols/ua.svg',
          title: 'uk flag',
          thumbnail: '../../assets/flags/world_capitols/ua.svg',
        },
        { // usa
          country: 'USA',
          path: '../../assets/flags/world_capitols/us.svg',
          title: 'us flag',
          thumbnail: '../../assets/flags/world_capitols/us.svg',
        },

];
export default imageData;