import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Swiper, SwiperSlide} from 'swiper/react'
import SelectLang from '../../components/dialogs/SelectLang'
// styles
import './Gallery.css'
import 'swiper/css'

export default function FullPacksCarousel({ fullpacks }) {
  const [open, setOpen] = useState(false)
  const [selectedPack, setSelectedPack] = useState("")
  const [currentPackTitle, setCurrentPackTitle] = useState("")
  const [packType, setPackType] = useState("full-packs")
  // const [isLangPack, setIsLangPack] = useState(true)
  const history = useHistory()

  console.log('fullpacks ', fullpacks)

  const handleClick = (pack) => {
    if(pack.isLangPack){
      setSelectedPack(pack.id);
      setCurrentPackTitle(pack.title);
      setOpen(true);//open dialog to select language
      setPackType('full-packs');
    } else if(!pack.isLangPack){
      setPackType('full-packs');
      setSelectedPack(pack.id);
      setCurrentPackTitle(pack.title);
      history.push(`${packType}/${pack.id}/cards`);
    }
    localStorage.setItem('currentPack', pack.title);
  }

  return (
    <>
    <Swiper
    spaceBetween={50}
    slidesPerView={2.75}
    slidesOffsetAfter={100}
    slidesOffsetBefore={50}
    // onSlideChange={() => console.log('slide change')}
    // onSwiper={(swiper) => console.log(swiper)}
  >
      {fullpacks.sort((a, b) => a.LWid - b.LWid).map(pack => {
        if (pack.isActive) {
          return (
          
            <SwiperSlide 
            key={pack.id} 
            onClick={() => {
              setSelectedPack(`full-packs/${pack.id}`);
              handleClick(pack);
              }}
              style={{
                width: '350px',
                minWidth: '380px',
                maxWidth: '450px',
                height: 'auto',
                border: '0px dotted red',
              }}>
          
              {/* <Link to={`full-packs/${pack.id}`}> */}
                <div className="pack-card">
                  {pack.isLangPack && <span className="lingoRibbon">MultiLingo&nbsp;</span>} 
                  <img src={pack.coverImage} alt=" " className="pack-cover" />
                  <p className="pack-title">{pack.title}</p>
                </div>
              {/* </Link> */}
            </SwiperSlide>
          
          );
        } else {
          return null;
        }
      })}
  
    </Swiper>
    <SelectLang selectedPack={selectedPack} packType={packType} open={open} setOpen={setOpen} />
    </>
  
  );
}